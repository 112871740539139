
import { getConnexion, getConnexionFormData } from "./auth.service";

export const getImmatriculationById = async (id) => {
  const result = await getConnexionFormData().get(`immatriculation/getImmatriculationByID/${id}`).then((resp) => {
    return resp.data
  })
  return result;
}

export async function getAllImmatriuculation() {
  
  const result = getConnexion().get('/immatriculation/getAllImmatriculation').then((resp) => {
       return resp.data.immatriculations;
   });
  return result;
 
}
export async function getAllImmatriculationValidee(){
  try{
   const result = getConnexion().get('/immatriculation/getAllImmatriculationValidee').then((resp)=>{
     return resp.data.immatriculations;
   });
   return result;
  }
  catch(ex){
       return null;
  }
}
export async function getAllImmatriculationToMutation(){
  try{
   const result = getConnexion().get('/mutation/immatriculationtionmutations').then((resp)=>{
     return resp.data.immatriculations;
   });
   return result;
  }
  catch(ex){
       return null;
  }
}
export async function isStatusAttente(immatriculation_id){
  try{
   const result = getConnexion().get(`/mutation/immatriculationtionmutations/${immatriculation_id}`).then((resp)=>{
     return resp.data.immatriculations;
   });
   return result;
  }
  catch(ex){
       return null;
  }
}
export async function getAllImmatriculationToReforme(){
  try{
   const result = getConnexion().get('/reforme/immatriculationtoreforme').then((resp)=>{
     return resp.data.immatriculations;
   });
   return result;
  }
  catch(ex){
       return null;
  }
}
export async function validedImmatriculation(immID)
{
  var formData = new FormData();
  formData.append('id',immID);
   const reponse = await getConnexion().post("immatriculation/validerImmatriculation",formData).then((resp) => {
     return resp.data;
    });
  // console.log(reponse);
   return reponse;
}
export const nouvelleImmatriculation = async (data) => {
  var formData =  new FormData();
  formData.append('modeImmatriculation',data.modeImmatriculation);
  formData.append('marque_id',data.marque);
  formData.append('model_id',data.model);
  formData.append('numChassie',data.numChassie);
  formData.append('carrosserie',data.carrosserie);
  formData.append('nbPlaceAssise',data.nbPlaceAssise);
  formData.append('nbPlaceDebout',data.nbPlaceDebout);
  formData.append('nbPorte',data.nbPorte);
  formData.append('kilometrage',data.kilometrage);
  formData.append('cylindre',data.cylindre);
  formData.append('annee',data.annee);
  formData.append('dateP',data.dateP);
  formData.append('energie',data.energie);
  formData.append('idpays',data.idpays);
  formData.append('genre',data.genre);
  formData.append('typeVehicule',data.type);
  formData.append('couleur',data.couleur);
  formData.append('acquisition',data.acquisition);
  formData.append('transmission',data.transmission);
  formData.append('ancienImmatriculation',data.ancienNumMat);
  formData.append('ministere',data.ministere);
  formData.append('direction',data.direction);
  formData.append('autreministere',data.autreministere);
  formData.append('lettreImage',data.image4);
  formData.append('faceImage',data.image1);
  formData.append('profileImage',data.image3);
  formData.append('backImage',data.image2);
  formData.append('nbreEssuie',data.nbreEssuie);
  formData.append('pv',data.pv);
  formData.append('cu',data.cu);
  formData.append('pa',data.pa);
  formData.append('autredirection',data.autredirection);
  const result = await getConnexionFormData().post(`/immatriculation/new`,formData).then((resp) => {return resp.data });
  return result;  
};
export const InitialiseImmatriculation = (immatriculation) =>{

  immatriculation.modeImmatriculation = '';
  immatriculation.idpays =  'DE';
  immatriculation.marque = 0;
  immatriculation.model = 0;
  immatriculation.carrosserie = 'Berline';
  immatriculation.genre = 0;
  immatriculation.type = 0;
  immatriculation.annee = '2010';
  immatriculation.energie = 0;
  immatriculation.numChassie = 'JN1FCAN15U0004510';
  immatriculation.nbPorte = '5';
  immatriculation.acquisition = 'achat';
  immatriculation.transmission = 'Manuelle';
  immatriculation.nbPlaceAssise = 4;
  immatriculation.nbPlaceDebout = 4;
  immatriculation.dateP = '10/10/2010';
  immatriculation.ancienNumMat = '';
  immatriculation.cylindre = '4';
  immatriculation.kilometrage = '1000';
  immatriculation.couleur = 'Rouge';
  immatriculation.ministere = 0;
  immatriculation.direction = 0;
  immatriculation.autreministere = '';
  immatriculation.image1 = '';
  immatriculation.image2 = '';
  immatriculation.image3 = '';
  immatriculation.image4 = '';
  immatriculation.nbreEssuie = '';
  immatriculation.ptc =4;
  immatriculation.pv =100;
  immatriculation.cu =23;
  immatriculation.pa = 1000
}
export const searchImmatriculation = (option,optionValue) => {
  const result = getConnexion().get(`/immatriculation/searchImmatriculation/${option}/${optionValue}`).then((resp) => {
       return resp.data.immatriculations;
  });
  return result;
}
export const resoumission = async (data) => {
  console.log(data);
  var formData =  new FormData();
  formData.append('immatriculation_id',data.immatriculation_id);
  formData.append('vehicule_id',data.vehiculeID);
  formData.append('pa',data.pa);
  formData.append('modeImmatriculation',data.modeImmatriculation);
  formData.append('marque_id',data.marque);
  formData.append('model_id',data.model);
  formData.append('numChassie',data.numChassie);
  formData.append('carrosserie',data.carrosserie);
  formData.append('nbPlaceAssise',data.nbPlaceAssise);
  formData.append('nbPlaceDebout',data.nbPlaceDebout);
  formData.append('nbPorte',data.nbPorte);
  formData.append('kilometrage',data.kilometrage);
  formData.append('cylindre',data.cylindre);
  formData.append('annee',data.annee);
  formData.append('dateP',data.dateP);
  formData.append('energie',data.energie);
  formData.append('idpays',data.idpays);
  formData.append('genre',data.genre);
  formData.append('typeVehicule',data.type);
  formData.append('couleur',data.couleur);
  formData.append('acquisition',data.acquisition);
  formData.append('transmission',data.transmission);
  formData.append('ancienImmatriculation',data.ancienNumMat != null ? data.ancienNumMat:'');
  formData.append('ministere',data.ministere);
  formData.append('direction',data.direction);
  formData.append('autreministere',data.autreministere);
  formData.append('lettreImage',typeof data.image4 === 'string' || data.image4 instanceof String  ?'':data.image4);
  formData.append('faceImage',typeof data.image1 === 'string' || data.image1 instanceof String ?'':data.image1);
  formData.append('profileImage',typeof data.image3 === 'string' || data.image3 instanceof String  ?'':data.image3);
  formData.append('backImage',typeof data.image2 === 'string' || data.image2 instanceof String  ?'':data.image2);
  formData.append('nbreEssuie',data.nbreEssuie);
  formData.append('pv',data.pv);
  formData.append('cu',data.cu);
  formData.append('created_by',data.created_by);
  formData.append('autredirection',data.autredirection);
  
  const result =  getConnexion().post('/immatriculation/resoumission',formData).then((resp) => {
    return resp.data;
  });
  return result;
}
export const dashboardStat =()=>{

  const result = getConnexion().get(`/immatriculation/dashboardStat`).then((resp) => {
        return resp.data; 
  });
  return result;
}
export const dashboardStatGraph1 = (mois = '')=>{
  const result = getConnexion().get(`/immatriculation/dashboardStat/graph1/${mois}`).then((resp) => {
      if(resp.data.success)
         return resp.data.stats
       else return [];   
  });
  return result;
}
export const dashboardStatGraph2 = ()=>{
  const result = getConnexion().get('/immatriculation/dashboardStat/graph2').then((resp) => {
      //console.log(resp.data)
      if(resp.data.success)
         return resp.data.stats
       else return [];   
  });
  return result;
}
export function createReforme(data) {
  var formData = new FormData();
  formData.append('immatriculation_id',data.immatriculation_id);
  formData.append('nom',data.nom);
  formData.append('prenom',data.prenom);
  formData.append('date_naissance',data.date_naissance);
  formData.append('fonction',data.fonction);
  formData.append('ministere_id',data.ministere);
  formData.append('direction_id',data.ministere);
  formData.append('piece',data.piece);
  formData.append('paiement',data.paiement);
  formData.append('valeurResiduelle',data.valeurResiduelle);
  const result = getConnexion().post('/reforme/new',formData).then((resp) => {
       return resp.data 
  });
  return result;
}
export function InitializedReforme(proprietaireInfo){
  proprietaireInfo.immatriculation_id= '';
  proprietaireInfo.valeurResiduelle=50000;
  proprietaireInfo.nom='KOUROUMA';
  proprietaireInfo.prenom='LANSANA';
  proprietaireInfo.date_naissance='1980-10-22';
  proprietaireInfo.fonction='Chef de Division';
  proprietaireInfo.ministere=2;
  proprietaireInfo.direction='';
  proprietaireInfo.piece= '';
  proprietaireInfo.paiement='';
  proprietaireInfo.oldministere_id='';
}
export function ImmatriculationforNewMinistere(data){
   const result = getConnexion().post('/immatriculation/ImmatriculationforNewMinistere',data).then((resp) => {
      return resp.data;
   });
   return result;
}
export function ImmatriculationRejeter(immatriculation_id){
  const result = getConnexion().get(`/immatriculation/rejet/${immatriculation_id}`).then((resp) => {
    return resp.data.rejet
  });
  return result;
}
export function mutation(data){
  const result = getConnexion().post('/mutation/new',data).then((resp) => {
      return resp.data
    });
  return result;
}
export function getAllReformes(){
  const result = getConnexion().get('/reforme/getAllReformes').then((resp) => {
      return resp.data.reformes;
  });
  return result;
}
export function getReformeByID(reforme_id)
{
  const result = getConnexion().get(`reforme/reforme/${reforme_id}`).then((resp) => {
    return resp.data;
  });
  return result;
}

export function ValiderReforme(data){
  const result =  getConnexion().post('reforme/valider',data).then((resp)=>{
    return resp.data;
  });
  return result;
}
export async function ResoumissionReforme(data){
  const result = await getConnexion().post('/reforme/resoumission',data).then((resp)=>{
    return resp.data;
  });
  return result;
}
export async function ResoumissionMutation(data){
  const result = await getConnexion().post('/mutation/resoumission',data).then((resp)=>{
    return resp.data;
  });
  return result;
}
export function getAllMutation(){
  const result = getConnexion().get('/mutation/mutations').then((resp) => {
     return resp.data
  });
  return result;
}
export function getMutationById(mutation_id){
  const result = getConnexion().get(`/mutation/getmutationBy/${mutation_id}`).then((resp) => {
    return resp.data;
  });
  return result;
}
export function statistique(){
  const result = getConnexion().get('/immatriculation/statistique/global').then((resp) => {
    return resp.data;
  });
  return result;
}
export function mutationValided(data){
  const result = getConnexion().post('mutation/valider',data).then((resp) =>{
    return resp.data;
  });
  return result;
}
export async function getReformeStatus(immatriculation_id){
  const result =  await getConnexion().get(`reforme/status/${immatriculation_id}`).then((resp) =>{
    return resp.data;
  });
  return result;
}
export async function getMutationStatus(immatriculation_id){
  const result =  await getConnexion().get(`mutation/status/${immatriculation_id}`).then((resp) =>{
    return resp.data;
  });
  return result;
}
//impression
export async function getAllImmatriculationValiderforImpression(){
  const result =  await getConnexion().get('/impression/getAllImmatriculationValidee').then((resp) =>{
    return resp.data;
  });
  return result;
}
export async function is_printed(immatriculation_id){
  const result =  await getConnexion().post(`/impression/printed/${immatriculation_id}`).then((resp) =>{
    return resp.data;
  });
  return result;
}