import { useNavigate } from "react-router-dom"

const LoginProtected = ({isLogging,children,redirectPath='/' }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    if(token || isLogging) {
        return children;
    }
    localStorage.removeItem('token');window.location.href=redirectPath;
    //navigate(redirectPath);
}

export default LoginProtected;