import { useState,useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import AG_GRID_TRANSLATE_FR from "../../services/utils/agGridLocalFr";
import { Helmet } from "react-helmet-async";
import { statistique } from "../../services/immatriculation.service";

const Statistiques = () => {
  const AG_FR = AG_GRID_TRANSLATE_FR();
  const [stats,setStats] = useState([]);
  const fakeData = [
    {
      immatriculation: 420,
      reforme: 128,
      mutation: 150,
      ep: 45,
      va: 36,
    },
  ];
  async function Element(){
    const{success,statistiques,messages} = await statistique();
   
      setStats([{
             'immatriculation':statistiques.valide,
            'reforme':statistiques.reforme,
            'mutation':statistiques.mutation,
            'rejete':statistiques.rejete,
            'ep':statistiques.EP,
            'va':statistiques.VA,}])
    
  } 
  useEffect(() => {
     Element()
  }, [])
  const [columnDefs] = useState([
    { headerName: "Validé", field: "immatriculation", filter: false },
    { headerName: "Reformé", field: "reforme", filter: false, resizable: true },
    { headerName: "Muté", field: "mutation", filter: false, resizable: true },
    { headerName: "Rejeté", field: "rejete", filter: false, resizable: true },
    { headerName: "EP", field: "ep", filter: false, resizable: true },
    { headerName: "VA", field: "va", filter: false, resizable: true },
    { flex: 1, headerName: "" },
  ]);

  return (
    <div className="page">
      <Helmet>
        <title>Statistiques</title>
      </Helmet>
      <h2>Statistiques</h2>
      <div className="ag-theme-material datagrid" style={{ width: "100%" }}>
        <AgGridReact
          rowData={stats}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
          pagination={true}
          paginationPageSize={10}
          localeText={AG_FR}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default Statistiques;
