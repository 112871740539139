import { useState,useEffect,useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { getAllMutation } from "../../services/immatriculation.service";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import AG_GRID_TRANSLATE_FR from "../../services/utils/agGridLocalFr";
import moment from "moment";
import 'moment/locale/fr';
import { Helmet } from 'react-helmet-async'
import { ComboContext } from "../../services/Context/Contexts";

const ListeMutation = () => {  
  const [immaData,setImmaData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const {ministeres} = useContext(ComboContext); 
  // const [ministeres,setMinisteres] = useState([]);
  const options = (params) => {
    const id = params.data.id;
    return (
      <div className="options">
        <Link to={`/details-mutation/${id}`}>
          <button>
            voir détails
          </button>
        </Link>
      </div>
    );
  };
  const AG_FR = AG_GRID_TRANSLATE_FR();
  async function getmutations(){
    setIsLoading(true);
      const {success,mutations} = await getAllMutation();
      let ord = 0;
      if(success){
        if(mutations && ministeres.length > 0)
            setImmaData(mutations.map((data) =>{ 
              var status = 'En attente';
                if(data.status == 1)
                  status = 'Validé';
                else if(data.status == 2)
                  status = 'Rejeté';  
                ord = ord + 1; 
                let ministere = '';
                if(data.status != 0) 
                  ministere = data.nomMinistere;
                else
                  ministere = ministeres.filter((m)=>m.ministere_id == data.ancienMinistereID)[0]?.nom ;
                    
                return{
                  'id': data.mutation_id,
                  "Ord": ord,
                  'date' : moment(data.updated_at).format('Do MMMM  YYYY'),
                  'modeImmatriculation':data.modeImmatriculation, 
                  'numImmatriculation':data.immatriculation_number,
                  'numChassis' : data.numChassie,
                  'marque': data.marque,
                  'modele':data.model,
                  'ministere':ministere,
                  'status': status,
                }
            }));
        }
   setIsLoading(false);      
   }
 useEffect(() => {
    getmutations();
 },[ministeres]);
 //console.log(ministeres.length)
  const [columnDefs] = useState([
    { headerName: "N°Ord.", field: "Ord", sortable: false, filter: false, width: 100 },
    { headerName: "Date", field: "date", sortable: true, filter: true, resizable: true, width: 150 },
    { headerName: "Mode Immat.", field: "modeImmatriculation", sortable: true, filter: true, resizable: true, width: 150 },
    { headerName: "Immatriculation", field: "numImmatriculation", sortable: true, filter: true, resizable: true },
    { headerName: "Numero de chassis", field: "numChassis", sortable: true, filter: true, resizable: true },
    { headerName: "Marque", field: "marque", sortable: true, filter: true },
    { headerName: "Modele", field: "modele", sortable: true, filter: true },
    { headerName: "Ministère", field: "ministere", sortable: true, filter: true, resizable: true },
    { headerName: "Status", field: "status", sortable: true, filter: true, resizable: true, width: 120 },
    { headerName: "Options", cellRenderer: options },
    { flex: 1, headerName: '' }
  ]);

  return (
    <div className="liste-mutation page">
      <Helmet>
        <title>Liste des mutations</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Liste des mutations</h2>
      <div className="ag-theme-material datagrid" style={{ width: "100%" }}>
        <AgGridReact
          rowData={immaData}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
          pagination={true}
          paginationPageSize={10}
          localeText={AG_FR}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default ListeMutation;