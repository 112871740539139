import { useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getImmatriculationById } from "../../services/immatriculation.service";
import { useForm } from "react-hook-form";
import Erreurs from "../../components/erreurs/Erreurs";
import Spinner from "../../components/Spinner/Spinner";
import { createNewMinistereandDirectionValided } from "../../services/organisation.service";
import { Helmet } from "react-helmet-async"

const ValidationMinistere = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const[organisation,setOrganisation] = useState({
    ministere : null,
    direction: null,
  });
  const [isautreministerecreated, setIsautreministerecreated] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [erreurs, setErreurs] = useState([]);
  const navigate = useNavigate();

  const getDetails = async (currentID) => {
    setIsLoading(true);
    const { immatriculation } = await getImmatriculationById(currentID);
    if (immatriculation.autreministere) {
      setOrganisation({ministere:immatriculation.autreministere,direction:immatriculation.autredirection});
      
    } else {
      toast.error("Cette Immatriculation a été déjà effectée à un ministère!");
      navigate("/liste-immatriculation");
    }
    setIsLoading(false);
  };
  const handleInput = (e) => {
    setOrganisation({...organisation,[e.target.name]:e.target.value});
  }
  const handSubmitForm = async () => {
    setErreurs([]);
    setIsLoading(true);
    var formData = new FormData();
    formData.append("immatriculation_id", id);
    formData.append("ministere", organisation.ministere);
    formData.append("direction", organisation.direction);
    const{success,messages} = await createNewMinistereandDirectionValided(formData);
    setIsLoading(false);
    if(!success){
      setErreurs(messages);
    } else{
      toast.success('Ministère ajouté avec succès');
      navigate(`/details-immatriculation/${id}`);
    } 
  };
  useLayoutEffect(() => {
    getDetails(id);
  }, []);

  return (
    <div className="validation-ministere page">
      <Helmet>
        <title>Validation de ministere</title>
      </Helmet>
        <h2>Validation de ministere</h2>
        {isLoading && <Spinner />}
        <form onSubmit={handleSubmit(handSubmitForm)}>
          <main>
            <div className="input-group">
              
              <label>Ministère
                <input type="text" placeholder="Ministere" value={organisation.ministere !== null ? organisation.ministere :'ministere'} name="ministere"
                    {...register('ministere', {
                      onChange: (e) => {
                        handleInput(e)
                      },
                     required:true,minLength:2},
                    )}
                />
                 {errors.ministere && errors.ministere?.type === "required" && (
                     <span className="error-msg">Le nom du ministère est obligatoire.</span>
                  )}
                  {errors.ministere && errors.ministere?.type === "minLength" && (
                      <span className="error-msg">Le caractère minimum est deux (2).</span>
                  )}
                          
              </label>
              <label>Direction
                <input type="text" value={organisation.direction !== null ? organisation.direction :''} name="direction"
                  {...register('direction', {
                    onChange: (e) => {
                      handleInput(e)
                    },
                   minLength:2},
                  )}
                />
                {errors.direction && errors.direction?.type === "minLength" && (
                      <span className="error-msg">Le caractère minimum est deux (2).</span>
                  )}
              </label>
          </div>
            <Erreurs validation = {erreurs} />
            <div className="buttons">
              <Link to={`/details-immatriculation/${id}`}>
                <button className="secondary"> Retour </button>
              </Link>
              <button className="validate" type="submit">
                Valider
              </button>
            </div>
          </main>
        </form>
      
    </div>
  );
};
export default ValidationMinistere;
