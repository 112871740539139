import { useState,useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { DepartmentsList } from "../../utils/data/fakeData";
import { Link } from "react-router-dom";
import { getministeres,deleteMinistere } from "../../services/organisation.service";
import AjouterMinistereModal from "../../components/AjouterMinistereModal/AjouterMinistereModal";
import Erreurs from "../../components/erreurs/Erreurs";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import AG_GRID_TRANSLATE_FR from "../../services/utils/agGridLocalFr";
import Spinner from "../../components/Spinner/Spinner";
import { Helmet } from 'react-helmet-async';
const ListeMinistere = () => {  
  const [isAddModal, setIsAddModal] = useState(false);
  const [ministeres,setMinisteres] = useState();
  const [erreurs,setErreurs] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  function getallministere(){
    setIsLoading(true)
    getministeres().then((resp) => {
      let ord = 0
      setMinisteres(resp.map((data) => {
        ord = ord + 1;
         return {
           'ord':ord,
           'id':data.ministere_id,
           'nom':data.nom
         }
      })
      );
    });
    setIsLoading(false)
  }
  const AG_FR = AG_GRID_TRANSLATE_FR();
  useEffect(() => {
    getallministere();      
      }, []);
  const handDeleteMinistere = (data)=>{
    setErreurs([]);
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Voulez-Vous Supprimé ce ministère?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, Supprimer!',
      cancelButtonText: "Annuler"
    }).then((result) => {
      if (result.isConfirmed) {
          if(data){
            var formData = new FormData();
            formData.append('ministere_id',data.id);
            deleteMinistere(formData).then((resp) => {
              //console.log(resp);
               if(resp.success){
                 toast.success('Suppression effectuée avec succès.');getallministere();   
               }else{
                 toast.error("Erreur");setErreurs(resp.messages);
               }
            })
          }
      }
    });
  }  
  const options = (params) => {
    const id = params.data.id;
    return (
      <div className="options">
        <Link to={`/modifier-ministere/${id}`}>
          <button>
              Modifier
          </button>
        </Link>
        <button className="delete-btn" onClick={() => handDeleteMinistere(params.data)}>Supprimer</button>
      </div>
    );
  };

  const [rowData] = useState(DepartmentsList);
  const [columnDefs] = useState([
    {
      headerName: "N°Ord",
      field: "ord",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Nom",
      field: "nom",
      sortable: true,
      filter: true,
      resizable: true,
      width: 450,
    },
    { headerName: "Options", cellRenderer: options},
    { flex: 1, headerName: "" },
  ]);

  return (
    <div className="liste-ministere page">
      <Helmet>
        <title>Liste des ministères</title>
      </Helmet>
      {isLoading && <Spinner />}
      <AjouterMinistereModal isOpen={isAddModal} setIsOpen={setIsAddModal} />
      <Erreurs validation = {erreurs} />
      <div className="head">
        <h2>Liste des ministères</h2>
        <button className="primary" onClick={() => setIsAddModal(true)}> + Nouveau ministère</button>
      </div>
      <div className="ag-theme-material datagrid" style={{ width: "100%" }}>
        <AgGridReact
          rowData={ministeres}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
          pagination={true}
          paginationPageSize={10}
          localeText={AG_FR}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default ListeMinistere;