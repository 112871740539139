import { useState,useEffect,useMemo,useContext } from "react";
import { useParams,useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Viewer from "react-viewer";
import RejectionModal from "../../components/RejectionModal/RejectionModal";
import { getMutationById,ImmatriculationRejeter,mutationValided } from "../../services/immatriculation.service";
import { getDirectionByID } from "../../services/organisation.service";
import { getPaysByID, isEmpty } from '../../utils/helper/functions';
import { getUserRole } from "../../services/auth.service";
import Erreurs from "../../components/erreurs/Erreurs";
import {ComboContext,UserContext} from "../../services/Context/Contexts";
import moment, { min } from 'moment';
import 'moment/locale/fr';
import Spinner from "../../components/Spinner/Spinner";
import toast from "react-hot-toast";
import { Helmet } from 'react-helmet-async';

const DetailsMutation = () => {
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const { id } = useParams();
   const url = process.env.REACT_APP_URL + '/storage/';
   const [mutationInfo,setMutationInfo] = useState({});
   const[anciendirection,setAncienDirection] = useState('');
   const[nouvelledirection,setNouvelledirection] = useState('');
   const[nouvaustatus,setNouvaustatus]=useState('');
   const[erreurs,setErreurs] = useState([]);
   const [role,setRole] = useState();
   const [isLoading,setIsLoading] = useState(false);
   const {currentUserPrivilege} = useContext(UserContext);
   const[isvalidedPrivilege,setIsvalidedPrivilege] = useState(false);
   const [isAgent,setisAgent] = useState(false);
   const [raisonRejet,setRaisonRejet] = useState(''); 
   const [ancienMinistere,setAncienMinistere] = useState('');
   const {ministeres} = useContext(ComboContext);
   const navigate = useNavigate();
  
  const pays = getPaysByID(mutationInfo.provenance);  
  const handShowRaisonRejet = ()=>{
    const raison = raisonRejet;
    Swal.fire({
      title: 'Motif de Rejet',
      text: raison,
      icon: 'info',
    })
  }
  useMemo(() => {
    setIsLoading(true);
    getDirectionByID(mutationInfo.ancienDirection_id).then((resp) => {
      setAncienDirection(resp);
    });

    getDirectionByID(mutationInfo.NouveauDirection_id).then((resp) => {
      setNouvelledirection(resp);
    }); 

    if(mutationInfo.NouveauStatus == 0)
    setNouvaustatus('En attente');
    else if(mutationInfo.NouveauStatus == 1)   
       setNouvaustatus('Validé');
   else if(mutationInfo.NouveauStatus == 2){ 
      ImmatriculationRejeter(mutationInfo.immatriculation_id).then((resp) => {
        if(resp.raison != null)
          setRaisonRejet(resp.raison);
        else setRaisonRejet(resp.autreraison);   
      });
    if(Array.isArray(currentUserPrivilege) && currentUserPrivilege.length > 0){
      const result  = currentUserPrivilege.filter(p => p.privilege == "Nouvelle Mutation");
      if(Array.isArray(result) && result.length > 0)
          if(mutationInfo.user_id == result[0].user_id)
              setisAgent(true)
      }    
      setNouvaustatus('Rejeté');   
   }
   const ministerID = mutationInfo.ancienMinistere_id;
   if(Array.isArray(ministeres) && ministeres.length > 0){
     const anicenMinistere = ministeres.filter((m) => m.ministere_id == ministerID);
       setAncienMinistere(anicenMinistere[0]?.nom);
   }
   setIsLoading(false);    
   },
  [currentUserPrivilege,pays]);
  async function getMutation(mutation_id){
    setIsLoading(true);
      const{success,mutation } = await getMutationById(mutation_id);
      if(success)
        setMutationInfo(mutation[0]);
    setIsLoading(false);   
  }
  
  useEffect(() =>{
    setIsLoading(true);
      getUserRole().then(resp=> setRole(resp)); 
      getMutation(id);
      if(Array.isArray(currentUserPrivilege) && currentUserPrivilege.length > 0){
        const result  = currentUserPrivilege.filter(p => p.privilege == "Validation");
      
        if(Array.isArray(result) && result.length > 0){
          setIsvalidedPrivilege(true);
        }
      }
   
    setIsLoading(false);
    
  },[id,currentUserPrivilege]);
  const handleImageClick = (index) => {
    setImageIndex(index);
    setIsVisible(true);
  };

  const handleValidation = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('mutation_id',id);
    Swal.fire({
      title: "Voulez-vous valider cette mutation ?",
      text: "Vous ne pourrez plus revenir en arrière",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a9d8f",
      cancelButtonColor: "#e63946",
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
         setIsLoading(true);
            mutationValided(formData).then((resp) =>{
                if( resp.success ){
                  setIsLoading(false);
                  toast.success("Mutation Effectuée avec succès");
                  navigate('/liste-mutation');
              }else{
                setIsLoading(false);
                toast.error("Erreur"); setErreurs(resp.messages);
              }
            });
          }
      
    });
  };

  const images = [
    { src: url + mutationInfo?.faceImage },
    { src: url + mutationInfo?.backImage },
    { src: url + mutationInfo?.profileImage },
    { src: url + mutationInfo?.lettreImage },
    { src: url + mutationInfo.document1 },
    { src: url + mutationInfo.document2  }
  ];
  //console.log(ancienMinistere);
  return (
    <div className="details-immatriculation page">
      <Helmet>
        <title>Détails de la mutation</title>
      </Helmet>
  
      <RejectionModal
        isOpen={isRejectionModalOpen}
        setIsOpen={setIsRejectionModalOpen} id={id} type="mutation"
      />
      <h2>Détails de la mutation </h2>
      {isLoading && <Spinner />}
      <div className="infos">
        <h3>Infos du vehicule</h3>
        <div className="info-group">
          <div>
            <span>Numero d'immatriculation</span>
            <strong>{mutationInfo?.immatriculation_number}</strong>
          </div>
          <div>
            <span>Provenance</span>
            <strong>{pays && pays}</strong>
          </div>
          <div>
            <span>Genre de véhicule</span>
            <strong>{mutationInfo?.genre}</strong>
          </div>
          <div>
            <span>Type</span>
            <strong>{mutationInfo?.typeVehicule}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Marque</span>
            <strong>{mutationInfo?.marque}</strong>
          </div>
          <div>
            <span>Modèle</span>
            <strong>{mutationInfo?.model}</strong>
          </div>
          <div>
            <span>Année</span>
            <strong>{mutationInfo?.madeYear}</strong>
          </div>
          <div>
            <span>Carosserie</span>
            <strong>{mutationInfo?.carosserie}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Energie</span>
            <strong>{mutationInfo?.energy}</strong>
          </div>
          <div>
            <span>Numero de chassis</span>
            <strong>{mutationInfo?.numChassie}</strong>
          </div>
          <div>
            <span>Nombre de portes</span>
            <strong>{mutationInfo?.nbPorte}</strong>
          </div>
          <div>
            <span>Places assises</span>
            <strong>{mutationInfo?.placeNumberAssis}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Places debout</span>
            <strong>{mutationInfo?.placeNumberDebout}</strong>
          </div>
          <div>
            <span>Premiere mise en cirulation</span>
            <strong>{moment(mutationInfo?.releaseYear).format("DD-MMMM-YYYY")}</strong>
          </div>
          <div>
            <span>Ancien numero d'immatriculation</span>
            <strong>{mutationInfo?.ancienImmatriculation ? mutationInfo?.ancienImmatriculation:'Non Fournie'}</strong>
          </div>
          <div>
            <span>Kilometrage</span>
            <strong>{mutationInfo?.kilometrage}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Cylindre</span>
            <strong>{mutationInfo?.cylinderNumber}</strong>
          </div>
          <div>
            <span>Nombre d'essieux</span>
            <strong>{mutationInfo?.nbreEssuie != null?mutationInfo?.nbreEssuie:0}</strong>
          </div>

          <div>
            <span>Poids à vide</span>
            <strong>{mutationInfo?.pv != null?mutationInfo?.pv:0}</strong>
          </div>
          <div>
            <span>Charge Utile</span>
            <strong>{mutationInfo?.cu != null ? mutationInfo?.cu : 0}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Poids total autorisé</span>
            <strong>{mutationInfo.pv != null && mutationInfo.cu != null ? parseInt(mutationInfo.pv) + parseInt(mutationInfo.cu):0}</strong>
          </div>
          <div>
            <span>Initiateur</span>
            <strong>{mutationInfo?.prenom && mutationInfo?.nom ? mutationInfo?.prenom.charAt(0).toUpperCase()+mutationInfo?.prenom.slice(1)  + " "+mutationInfo?.nom.charAt(0).toUpperCase()+mutationInfo?.nom.slice(1):'Non Fournie'}</strong>
          </div>
          <div></div>
          <div></div>
        </div>

        <div className="info-group images-group">
          <div>
            <span>Photo de face</span>
            <div className="image" onClick={() => handleImageClick(0)}>
              <img src={mutationInfo?.faceImage !== undefined  ? url + mutationInfo?.faceImage:""} alt="" id="image" />
            </div>
          </div>
          <div>
            <span>Photo de dos</span>
            <div className="image" onClick={() => handleImageClick(1)}>
              <img src={mutationInfo?.backImage !== undefined  ? url + mutationInfo?.backImage:""} alt="" />
            </div>
          </div>
          <div>
            <span>Photo de profil</span>
            <div className="image" onClick={() => handleImageClick(2)}>
              <img src={mutationInfo?.profileImage !== undefined  ? url + mutationInfo?.profileImage:""} alt="" />
            </div>
          </div>
          <div>
            <span>Fond de Dossier</span>
            <div className="image" onClick={() => handleImageClick(3)}>
              <img src={mutationInfo?.lettreImage !== undefined  ? url + mutationInfo?.lettreImage:""} alt="" />
            </div>
          </div>
        </div>

        <h3>Ancienne attribution</h3>
        <div className="info-group">
          <div>
            <span>Ancienne affectation</span>
            <strong>{ancienMinistere?ancienMinistere:'Non Fournie'}</strong>
          </div>
          <div>
            <span>Ancienne direction</span>
            <strong>{anciendirection ?anciendirection:'Non Fournie' }</strong>
          </div>
          <div>
            <span>Date d'attribution</span>
            <strong>{moment(mutationInfo?.ancienDteAttribution).format("DD-MMMM-YYYY")}</strong>
          </div>
          <div></div>
        </div>

        <h3>Nouvelle mutation</h3>
        <div className="info-group">
          <div>
            <span>Departement</span>
            <strong>{mutationInfo?.NouveMinistere}</strong>
          </div>
          <div>
            <span>Direction</span>
            <strong>{nouvelledirection ? nouvelledirection :"Non Fournie"}</strong>
          </div>
          <div>
            <span>Date de mutation</span>
            <strong>{moment(mutationInfo?.created_at).format("DD-MMMM-YYYY")}</strong>
          </div>
          <div>
             <span>Status</span>
             {raisonRejet != ''?
                  <strong style={{color:'red',cursor:'pointer',textDecoration:'underline'}} onClick={handShowRaisonRejet}> {nouvaustatus} </strong> 
                  :
                  <strong> {nouvaustatus} </strong> 
               }
          </div>
        </div>

        <div className="info-group">
        {mutationInfo?.document1 != undefined &&
          <div>
            <span>Document 1</span>
            <div className="image" onClick={() => handleImageClick(4)}>
              <img src={ url + mutationInfo.document1} alt="" />
            </div>
          </div>}
          {mutationInfo?.document2 != undefined &&
          <div>
            <span>Document 2</span>
            <div className="image" onClick={() => handleImageClick(5)}>
              <img src={url + mutationInfo.document2} alt="" />
            </div>
          </div>}
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="image-viewer">
        <Viewer
          visible={isVisible}
          onClose={() => {
            setIsVisible(false);
          }}
          images={images}
          activeIndex={imageIndex}
        />
      </div>
      {
        (isAgent && mutationInfo.NouveauStatus == 2) &&
          <>
            <div className="buttons">
              <Link to='/liste-mutation'>
                  <button className="secondary">
                    Retour
                  </button>
                  
              </Link>
                <Link to={`/resoumission-mutation/${mutationInfo.mutation_id}`} state={{ mutationInfo:mutationInfo}}>
                   <button className="primary">
                        Resoumettre
                   </button>
             
                </Link>
            </div>    
          </>
        
        }
      {(isvalidedPrivilege && mutationInfo.NouveauStatus == 0)  &&
       <>
         <Erreurs validation = {erreurs} />
         <div className="buttons">
          <button className="validate" onClick={handleValidation}>
            Valider
          </button>
          <button
            className="reject-btn"
            onClick={() => setIsRejectionModalOpen(true)}
          >
            {" "}
            Rejeter{" "}
          </button>
        </div>
       </> 
         }
    </div>
  );
};

export default DetailsMutation;
