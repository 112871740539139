export const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export const ImmatriculationsData = [
  {
    id: "1",
    exploitation: "VA",
    numImmatriculation: "VA-0000-A",
    numChassis: "1GHDS25DG8TD1236D",
    marque: "Toyota",
    modele: "Corolla",
    annee: 2021,
    carosserie: "Berline",
    energie: "Essence",
    ministere: "Ministère de la santé",
    status: "Soumis",
    provenance: "Inde",
    genre: "Vehicule léger",
    type: "Voiture",
    chassis: "KLHDFD5454DFD",
    portes: 4,
    placesAssises: 5,
    placesDebout: 0,
    dateMiseEnCirculation: "12/05/2019",
    ancienNumeroImmat: "RC-2623-B",
    kilometrage: 2000,
    cylindre: 2,
    date: "09/05/2022",
    photoFace: "https://cdn.motor1.com/images/mgl/G4z31/s3/tesla-roadster.jpg",
    photoDos: "https://www.motorlegend.com/modules/breve/photos/high/tesla-roadster-22389-1-P.jpg",
    photoProfil: "https://cdn.motor1.com/images/mgl/R07qr/s1/tesla-roadster.jpg",
  },
  {
    id: "2",
    exploitation: "EP",
    numImmatriculation: "EP-0123-A",
    numChassis: "1GHDS25DG8TD1236D",
    marque: "Mazda",
    modele: "M",
    annee: 2018,
    carosserie: "Berline",
    energie: "Essence",
    ministere: "Ministère de la santé",
    status: "Soumis",
    provenance: "Inde",
    genre: "Vehicule léger",
    type: "Voiture",
    chassis: "KLHDFD5454DFD",
    portes: 4,
    placesAssises: 5,
    placesDebout: 0,
    dateMiseEnCirculation: "12/05/2019",
    ancienNumeroImmat: "RC-2623-B",
    kilometrage: 2000,
    cylindre: 2,
    date: "09/05/2022",
    photoFace: "https://cdn.motor1.com/images/mgl/G4z31/s3/tesla-roadster.jpg",
    photoDos: "https://www.motorlegend.com/modules/breve/photos/high/tesla-roadster-22389-1-P.jpg",
    photoProfil: "https://cdn.motor1.com/images/mgl/R07qr/s1/tesla-roadster.jpg",
  },
  {
    id: "3",
    exploitation: "VA",
    numImmatriculation: "VA-0002-A",
    numChassis: "1GHDS25DG8TD1236D",
    marque: "Mercedes",
    modele: "Benz",
    annee: 2021,
    carosserie: "SUV",
    energie: "Hybride",
    ministere: "Ministère du transport",
    status: "Validé",
    provenance: "Inde",
    genre: "Vehicule léger",
    type: "Voiture",
    chassis: "KLHDFD5454DFD",
    portes: 4,
    placesAssises: 5,
    placesDebout: 0,
    dateMiseEnCirculation: "12/05/2019",
    ancienNumeroImmat: "RC-2623-B",
    kilometrage: 2000,
    cylindre: 2,
    date: "09/05/2022",
    photoFace: "https://cdn.motor1.com/images/mgl/G4z31/s3/tesla-roadster.jpg",
    photoDos: "https://www.motorlegend.com/modules/breve/photos/high/tesla-roadster-22389-1-P.jpg",
    photoProfil: "https://cdn.motor1.com/images/mgl/R07qr/s1/tesla-roadster.jpg",
  },
  {
    id: "4",
    exploitation: "VA",
    numImmatriculation: "VA-0003-A",
    numChassis: "1GHDS25DG8TD1236D",
    marque: "Land Rover",
    modele: "Evoque",
    annee: 2016,
    carosserie: "SUV",
    energie: "Electrique",
    ministere: "Ministère des mines",
    status: "Validé",
    provenance: "Inde",
    genre: "Vehicule léger",
    type: "Voiture",
    chassis: "KLHDFD5454DFD",
    portes: 4,
    placesAssises: 5,
    placesDebout: 0,
    dateMiseEnCirculation: "12/05/2019",
    ancienNumeroImmat: "RC-2623-B",
    kilometrage: 2000,
    cylindre: 2,
    date: "09/05/2022",
    photoFace: "https://cdn.motor1.com/images/mgl/G4z31/s3/tesla-roadster.jpg",
    photoDos: "https://www.motorlegend.com/modules/breve/photos/high/tesla-roadster-22389-1-P.jpg",
    photoProfil: "https://cdn.motor1.com/images/mgl/R07qr/s1/tesla-roadster.jpg",
  },
];

export const DepartmentsList = [
  {
    id: "1",
    name: "Ministère des affaires étrangères"
  },
  {
    id: "2",
    name: "Ministère du commerce"
  },
  {
    id: "3",
    name: "Ministère des finances"
  },
  {
    id: "4",
    name: "Ministère de la pêche et de l'aquaculture"
  },
  {
    id: "5",
    name: "Ministère de la Santé"
  },
  {
    id: "6",
    name: "Ministère des mines"
  },
]

export const ServicesList = [
  {
    id: "1",
    name: "Service des ressources humaines"
  },
  {
    id: "2",
    name: "Service d'integration"
  },
  {
    id: "3",
    name: "Service des systèmes d'information"
  },
  {
    id: "4",
    name: "Secretariat"
  },
]

export const fakeUsers = [
  {
    id: '1',
    username: 'user1',
    firstName: 'User',
    lastName: 'One',
    role: 'Administrator',
    password: '123456',
    tel: "622 00 00 00",
    email: "amazingemail@awesome.com"
  },
  {
    id: '2',
    username: 'user2',
    firstName: 'User',
    lastName: 'Two',
    role: 'Agent',
    password: '123456',
    tel: "622 00 00 00",
    email: "amazingemail@awesome.com"
  },
  {
    id: '3',
    username: 'user3',
    firstName: 'User',
    lastName: 'Three',
    role: 'Agent',
    password: '123456',
    tel: "622 00 00 00",
    email: "amazingemail@awesome.com"
  },
  {
    id: '4',
    username: 'user4',
    firstName: 'User',
    lastName: 'Four',
    role: 'Directeur',
    password: '123456',
    tel: "622 00 00 00",
    email: "amazingemail@awesome.com"
  },
]

export const fakeRoles = [
  {
    id: '1',
    name: 'Agent',
    privileges: ["Immatriculation, Mutation, Reforme"]
  },
  {
    id: '2',
    name: 'Superviseur',
    privileges: ["Validation", "Impression cartes grises"]
  },
  {
    id: '3',
    name: 'Directeur',
    privileges: ["Validation", "Impression cartes grises", "Utilisateurs", "Ministères", "Roles"]
  },
]

export const fakePrivileges = [
  {
    id: '1',
    value: 'immatriculation',
    label: 'Nouvelle immatriculation',
  },
  {
    id: '2',
    value: 'mutation',
    label: 'Nouvelle mutation',
  },
  {
    id: '3',
    value: 'reforme',
    label: 'Nouvelle reforme',
  },
  {
    id: '4',
    value: 'validation',
    label: 'Validation',
  },
  {
    id: '5',
    value: 'ministere',
    label: 'Gestion des ministères',
  },
  {
    id: '6',
    value: 'utilisateur',
    label: 'Gestion des utilisateurs',
  },
  {
    id: '7',
    value: 'role',
    label: 'Gestion des roles',
  },
  {
    id: '8',
    value: 'cartesgrises',
    label: 'Impression cartes grises',
  },
]

export const fakeBrands = [
  {
    id: "1",
    name: "Toyota",
  },
  {
    id: "2",
    name: "Mercedes",
  },
  {
    id: "3",
    name: "Land Rover",
  },
  {
    id: "4",
    name: "Tesla",
  },
];

export const fakeModels = [
  {
    id: "1",
    name: "Corolla",
  },
  {
    id: "2",
    name: "Camaro",
  },
  {
    id: "3",
    name: "Evoque",
  },
  {
    id: "4",
    name: "Clio",
  },
];
