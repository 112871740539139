import { useState,useContext,useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { Link } from "react-router-dom";
import { getAllImmatriculationToMutation, getAllImmatriculationValidee, isStatusAttente } from "../../services/immatriculation.service";
import { ComboContext } from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import AG_GRID_TRANSLATE_FR from "../../services/utils/agGridLocalFr";
import moment from "moment";
import 'moment/locale/fr';
import { Helmet } from 'react-helmet-async'

const Mutation = () => {  
  const [isLoading,setIsLoading] = useState(false);
 
  const [immaData,setImmaData] = useState([]);
  const {ministeres} = useContext(ComboContext); 
  const [isValid,setIsValid] = useState(false);
  const AG_FR = AG_GRID_TRANSLATE_FR();
  const options = (params) => {
    const id = params.data.id;
    return (
      <div className="options">
        <Link to={`/nouvelle-mutation/${id}`}>
          <button>
            Muter
          </button>
        </Link>
      </div>
    );
  };
 
  async function getImmatriculations(){
    setIsLoading(true);
      if( Array.isArray(ministeres) && ministeres.length > 0 ){
          getAllImmatriculationToMutation().then((resp) => {
            let ord = 0;  
            setImmaData(resp.map((data) => {
                let ministere = '';
                if(data?.statusMutation != 0 && data?.statusMutation != 'null'){
                  if(data.minister_id !== 1000000){
                    const ministereName =  ministeres.filter((m)=>m.ministere_id === data.minister_id);
                    if(Array.isArray(ministereName) && ministereName.length > 0){
                      ministere = ministereName[0].nom;
                    }
                  }else
                    ministere = data.autreministere;
                  
                }
                else{
                  if(data.oldministere_id !== 1000000){
                    const ministereName =  ministeres.filter((m)=>m.ministere_id === data.oldministere_id);
                    if(Array.isArray(ministereName) && ministereName.length > 0){
                      ministere = ministereName[0].nom;
                    }
                  }else
                    ministere = data.autreministere;
                  // ministere = data.oldministere_id != 1000000 ? ministeres.filter((m)=>m.ministere_id == data.oldministere_id)[0].nom:data.autreministere;
                }
              
                ord = ord + 1;
              
                return {
                  'id': data.immatriculation_id,
                  "Ord": ord,
                  'date' : moment(data.updated_at).format('Do MMMM  YYYY'),
                  'modeImmatriculation':data.modeImmatriculation, 
                  'numImmatriculation':data.immatriculation_number,
                  'numChassis' : data.numChassie,
                  'marque': data.marque,
                  'modele':data.model,
                  'ministere':ministere,
          
                }
            }));
          }) ; 
      }
    setIsLoading(false); 
  } 
  useEffect(() => {
    getImmatriculations();
  }, [ministeres]);
 
  const [columnDefs] = useState([
    { headerName: "N°Ord.", field: "Ord", sortable: false, filter: false, width: 100 },
    {
      headerName: "Mode Immat.",
      field: "modeImmatriculation",
      sortable: true,
      filter: true,
      resizable: true,
      width: 150,
    },
    {
      headerName: "Immatriculation",
      field: "numImmatriculation",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Numero de chassis",
      field: "numChassis",
      sortable: true,
      filter: true,
      resizable: true,
    },
    { headerName: "Marque", field: "marque", sortable: true, filter: true },
    { headerName: "Modele", field: "modele", sortable: true, filter: true },
    {
      headerName: "Ministère",
      field: "ministere",
      sortable: true,
      filter: true,
      resizable: true,
    },
    { headerName: "Options", cellRenderer: options },
    { flex: 1, headerName: "" },
  ]);
  const afterFilterChanged = () => console.log(options.api.inMemoryRowController.rowsAfterFilter)


  return (
    <div className="mutation page">
      <Helmet>
        <title>Mutation</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Mutation de propriété</h2>
      <div className="ag-theme-material datagrid" style={{ width: "100%" }}>
        <AgGridReact
          rowData={immaData}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
          pagination={true}
          paginationPageSize={10}
          afterFilterChanged= {afterFilterChanged}
          localeText={AG_FR}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default Mutation;
