import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import moment from "moment";
import "moment/locale/fr";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import AjouterUtilisateurModal from "../../components/AjouterUtilisateurModal/AjouterUtilisateurModal";
import ModifierUtilisateurModal from "../../components/ModifierUtilisateurModal/ModifierUtilisateurModal";
import Spinner from "../../components/Spinner/Spinner";
import Erreurs from "../../components/erreurs/Erreurs";
import { deleteUser, getAllUsers } from "../../services/auth.service";
import AG_GRID_TRANSLATE_FR from "../../services/utils/agGridLocalFr";
const ListeUtilisateurs = () => {
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [erreurs, setErreurs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isDelete, setisDelete] = useState(false);
  const AG_FR = AG_GRID_TRANSLATE_FR();
  const handleEditUser = (data) => {
    setIsEditModal(true);
    setSelectedUser(data);
  };

  const options = (params) => {
    const id = params.data.id;
    return (
      <div className="options">
        <button
          onClick={() => {
            handleEditUser(params.data);
          }}
        >
          Modifier
        </button>
        <button className="delete-btn" onClick={() => { deleteHandle(params.data.id)}}>
          Supprimer
        </button>
      </div>
    );
  };

  const deleteHandle = (id) => {
    setErreurs([]);
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Voulez-Vous Supprimé cet utilisateur?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, Supprimer!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        var formData = new FormData();
        formData.append("user_id", id);
        setIsLoading(true);
        deleteUser(formData).then((resp) => {
          if (resp.status == 200) {
            toast.success("Suppression effectuée avec succès");
            setisDelete(!isDelete);
          } else {
            toast.error("Vous aviez fait des erreurs.");
            setErreurs(resp.messages);
          }
        });
        setIsLoading(false);
      }
    });
  };

  async function getallusers() {
    setIsLoading(true);
    const { status, users, messages } = await getAllUsers();
    setUserData([]);
    if (status === 200) {
      setIsLoading(false);
      let ord = 0;
      setUserData(
        users.map((user) => {
          ord = ord + 1;
          return {
            id: user.user_id,
            ord: ord,
            nom: user.nom,
            prenom: user.prenom,
            username: user.username,
            telephone: user.telephone,
            email: user.email,
            role_id: user.role_id,
            role: user.nom_role,
            updated_at: moment(user.updated_at, "YYYYMMDD HH:mm:ss").fromNow(),
          };
        })
      );
    } else {
      setIsLoading(false);
      setErreurs(messages);
    }
  }

  useEffect(() => {
    getallusers();
  }, [isEditModal, isDelete,isAddModal]);

  const [columnDefs] = useState([
    {
      headerName: "N°Ord",
      field: "ord",
      sortable: false,
      filter: false,
      resizable: true,
      width: 100,
    },
    {
      headerName: "Nom d'utilisateur",
      field: "username",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Nom",
      field: "nom",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Prenom",
      field: "prenom",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Role",
      field: "role",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Dernière Cnx.",
      field: "updated_at",
      sortable: true,
      filter: true,
      resizable: true,
    },
    { headerName: "Options", cellRenderer: options },
    { flex: 1, headerName: "" },
  ]);

  return (
    <div className="liste-utilisateurs page">
      <Helmet>
        <title>Liste des utilisateurs</title>
      </Helmet>
      {isLoading && <Spinner />}
      <AjouterUtilisateurModal isOpen={isAddModal} setIsOpen={setIsAddModal} />
      <ModifierUtilisateurModal
        isOpen={isEditModal}
        setIsOpen={setIsEditModal}
        selectedUser={selectedUser}
      />
      <Erreurs validation={erreurs} />
      <div className="head">
        <h2>Liste des utilisateurs</h2>

        <button className="primary" onClick={() => setIsAddModal(true)}>
          + Nouvel utilisateur
        </button>
      </div>
      <div className="ag-theme-material datagrid" style={{ width: "100%" }}>
        <AgGridReact
          rowData={userData}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
          pagination={true}
          paginationPageSize={10}
          localeText={AG_FR}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default ListeUtilisateurs;
