import { useState, useEffect } from "react";
import StepOne from "../../components/ResoumissionImmatriculation/StepOne";
import StepTwo from "../../components/ResoumissionImmatriculation/StepTwo";
import StepThree from "../../components/ResoumissionImmatriculation/StepThree";
import StepFour from "../../components/ResoumissionImmatriculation/StepFour";
import StepFive from "../../components/ResoumissionImmatriculation/StepFive";
import { getAllGenre, getAlltype, getAllMarques, getAllmodel } from "../../utils/vehicule.util";
import { getministeres, getAllDirections } from "../../services/organisation.service";
import { getImmatriculationById } from "../../services/immatriculation.service";
import Spinner from "../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import moment from "moment";
import 'moment/locale/fr';
import { Helmet } from 'react-helmet-async';

const ResoumissionImmatriculation = () => {
  const [step, setStep] = useState(1);
  const [stepChk, setStepChk] = useState({ step1: false, step2: false, step3: false, step4: false,step5:false, });
  const [isLoading, setIsLoading] = useState(false);
  const [immatriculation, setImmatriculation] = useState({
    modeImmatriculation: "",
    idpays: "DE",
    marque: 0,
    model: "0",
    carrosserie: "Berline",
    genre: "0",
    type: "",
    annee: "2010",
    energie: "Essence",
    numChassie: "JN1FCAN15U0004510",
    nbPorte: 5,
    proprietaire: 0,
    acquisition: "achat",
    transmission: "",
    nbPlaceAssise: 4,
    nbPlaceDebout: 4,
    dateP: "2010-04-07",
    carosserie: "",
    ancienNumMat: "RC-8938-BA",
    cylindre: 4,
    kilometrage: "1000",
    couleur: "Rouge",
    dateImmat: "",
    ministere: 0,
    direction: "",
    autreministere: "",
    nbreEssuie: 20,
    autredirection: "",
    categories: {
      AllGenres: [],
      AllTypes: [],
    },
    brands: {
      allMarques: [],
      allModeles: [],
    },
    organisations: {
      allMinisteres: [],
      allDirections: [],
    },
    pa:'10CV',
    ptc: 24,
    pv: 10,
    cu: 15,
    image1: "",
    image2: "",
    image3: "",
    image4: "",
  });
  const {id} = useParams();
  async function ComboElements() {
    setIsLoading(true);
    const marques = await getAllMarques();
    const modeles = await getAllmodel();
    const genres = await getAllGenre();
    const typeVehicule = await getAlltype();
    const ministeres = await getministeres();
    const directions = await getAllDirections();
    const {status,immatriculation,vehicule} = await getImmatriculationById(id);
    if(status) {     
        setImmatriculation({
          ...immatriculation,
          vehiculeID:vehicule.vehicule_id,
          immatriculation_id:immatriculation.immatriculation_id,
          modeImmatriculation:immatriculation.modeImmatriculation,
          idpays: vehicule.provenance,
          model: vehicule.model_id,
          marque:vehicule.marque_id,
          carrosserie: vehicule.carosserie,
          genre: vehicule.genre,
          type: vehicule.typeVehicule,
          annee: vehicule.madeYear,
          energie: vehicule.energy,
          numChassie: vehicule.numChassie,
          nbPorte: vehicule.nbPorte,
          acquisition: vehicule.acquisition,
          transmission: vehicule.transmission,
          nbPlaceAssise: vehicule.placeNumberAssis,
          nbPlaceDebout:vehicule.placeNumberDebout,
          dateP: vehicule.releaseYear,
          ancienNumMat: immatriculation.ancienImmatriculation,
          cylindre: vehicule.cylinderNumber,
          kilometrage: vehicule.kilometrage,
          couleur: vehicule.colorVehicule,
          updatedDay:moment(vehicule.updated_at).fromNow(),
          createdDay:moment(vehicule.created_at).fromNow(),
          ministere: immatriculation.minister_id,
          direction: immatriculation.direction_id,
          created_by:immatriculation.created_by,
          validate_by:immatriculation.valided_by,
          nbreEssuie:vehicule.nbreEssuie,
          pv:vehicule.pv,
          cu:vehicule.cu,
          pa:vehicule.pa,
          image1: vehicule.faceImage,
          image2: vehicule.backImage,
          image3: vehicule.profileImage,
          image4: vehicule.lettreImage,
          brands: {
            allMarques: marques,
            allModeles: modeles,
          },
          categories: {
            AllGenres: genres,
            AllTypes: typeVehicule,
          },
          organisations: {
            allMinisteres: ministeres,
            allDirections: directions,
          },
        });
        setStepChk({...stepChk,step1:true})
      }
    setIsLoading(false);
  }

  useEffect(() => {
    ComboElements();
  }, []);

  const handleNextStep = () => {
    setStep(step + 1);
    //console.log(immatriculation);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="immatriculation-page page">
      <Helmet>
        <title>Resoumission d'immatriculation</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Resoumission d'immatriculation</h2>
      <div className="stepper">
        <div
          className={`step ${step === 1 && "step-active"}`}
          onClick={() => setStep(1)}
        >
          <div className="step-number">
            <span>1</span>
          </div>
          <div className="step-label">
            <p>Mode d'immatriculation</p>
          </div>
        </div>
        <div
          className={`step ${step === 2 && "step-active"}`}
          onClick={() =>
            immatriculation.modeImmatriculation.length  > 0 && setStep(2)
          }
        >
          <div className="step-number">
            <span>2</span>
          </div>
          <div className="step-label">
            <p>Infos du vehicule</p>
          </div>
        </div>
        <div
          className={`step ${step === 3 && "step-active"}`}
          onClick={() => stepChk.step3 && setStep(3)}
        >
          <div className="step-number">
            <span>3</span>
          </div>
          <div className="step-label">
            <p>Infos du proprietaire</p>
          </div>
        </div>
        <div
          className={`step ${step === 4 && "step-active"}`}
          onClick={() => stepChk.step4 && setStep(4)}
        >
          <div className="step-number">
            <span>4</span>
          </div>
          <div className="step-label">
            <p>Joindre des fichiers</p>
          </div>
        </div>
        <div
          className={`step ${step === 5 && "step-active"}`}
          onClick={() => stepChk.step5 && setStep(5)}
        >
          <div className="step-number">
            <span>5</span>
          </div>
          <div className="step-label">
            <p>Soumission</p>
          </div>
        </div>
      </div>
      <div className="step-components">
        {step === 1 && (
          <StepOne
            handleNextStep={handleNextStep}
            immatriculation={immatriculation}
            setImmatriculation={setImmatriculation}
          />
        )}
        {step === 2 && (
          <StepTwo
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            immatriculation={immatriculation}
            setImmatriculation={setImmatriculation}
            stepChk={stepChk}
            setStepChk={setStepChk}
          />
        )}
        {step === 3 && (
          <StepThree
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            immatriculation={immatriculation}
            setImmatriculation={setImmatriculation}
            stepChk={stepChk}
            setStepChk={setStepChk}
          />
        )}
        {step === 4 && (
          <StepFour
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            immatriculation={immatriculation}
            setImmatriculation={setImmatriculation}
            stepChk={stepChk}
            setStepChk={setStepChk}
          />
        )}
        {step === 5 && (
          <StepFive
            handlePrevStep={handlePrevStep}
            setImmatriculation={setImmatriculation}
            immatriculation={immatriculation}
            stepChk={stepChk}
            setStepChk={setStepChk}
            setStep={setStep}
          />
        )}
      </div>
    </div>
  );
};

export default ResoumissionImmatriculation;
