import { useState,useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const StepOne = ({ handleNextStep ,immatriculation,setImmatriculation}) => {
  const [status,setStatus] = useState(false);
  const {id} = useParams();
  
  useEffect(() => {
    if(immatriculation.modeImmatriculation != '')
     setStatus(true)
    else setStatus(false); 
  },[immatriculation]) 

  const handleChange = (e)=>{
     setImmatriculation({...immatriculation,modeImmatriculation:e.target.value});
     if( e.target.value.length > 0 ){
       setStatus(true);
     }else {
      setStatus(true);
     }
  }
  return (
    
    <div className="step-one">
      <form>
        <label htmlFor="modeImmatriculation">Choisissez le mode d'immatriculation</label>
        <select name="modeImmatriculation" value={immatriculation.modeImmatriculation} className="type" id="type" onChange={handleChange}>
            <option value='' >Choisissez un mode d'immatriculation</option>
            <option value="VA">VA</option>
            <option value="EP">EP</option>
        </select>
        <Link to={`/details-immatriculation/${id}`} style={{marginRight:'5px'}}>
           <button type="button" className="secondary">Retour</button>
        </Link>
        <button onClick={handleNextStep} disabled={status?false:true}>Suivant</button>
      </form>
    </div>
  );
};

export default StepOne;
