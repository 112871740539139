export  const carosseries = [
  {
      id:'Berline',
      nom:'Berline'
  },
  {
    id:'SUV 4D',
    nom:'SUV 4D'
  },
  {
    id:'Sedan 4D',
    nom:'Sedan 4D'
  },
  {
    id:'MONOSPACE',
    nom:'MONOSPACE'   
  },
  {
    id:'CABRIOLET',
    nom:'CABRIOLET'   
  },
  {
    id:'LIMOUSINE',
    nom:'LIMOUSINE'  
  },
  {
      id:'SPORTS CAR',
      nom:'SPORTS CAR'
  },
  {
    id:'STATION WAGON',
    nom:'STATION WAGON'
  },
  {
    id:'HATCHBACK',
    nom:'HATCHBACK'
  },
  {
      id:'CONVERTIBLE',
      nom:'CONVERTIBLE'
  },
  {
    id:'PICKUP TRUCK',
    nom:'PICKUP TRUCK'
  },
  {
    id:'MINIVAN',
    nom:'MINIVAN'
  },
  {
    id:'MOTO',
    nom:'MOTO'
  },
  {
    id:'PLATEAU',
    nom:'PLATEAU'
  },
  {
    id:'FOURGON',
    nom:'FOURGON'
  },
  {
    id:'PORTE-CONTENEURS',
    nom:'PORTE-CONTENEURS'
  },
  {
    id:'CITERNE',
    nom:'CITERNE'
  },
  {
    id:'SAVOYARDE',
    nom:'SAVOYARDE'
  },
  {
    id:'BUS',
    nom:'BUS'
  }


]