import { useState, useEffect ,useMemo,useContext} from "react";
import { useParams,useNavigate, Link } from "react-router-dom";
import LicensePlate from "../../components/LicensePlate/LicensePlate";
import { getImmatriculationById,validedImmatriculation,ImmatriculationRejeter,getReformeStatus,getMutationStatus } from "../../services/immatriculation.service";
import { getPaysByID, isEmpty } from '../../utils/helper/functions';
import moment from 'moment';
import Swal from "sweetalert2"
import RejectionModal from '../../components/RejectionModal/RejectionModal';
import {ComboContext,UserContext} from "../../services/Context/Contexts";
import { getUserRole,userByID,getCurrentUser } from "../../services/auth.service";
import { getMinistereById } from "../../services/organisation.service";
import { Helmet } from 'react-helmet-async'
import 'moment/locale/fr';
import toast from "react-hot-toast";
import Viewer from 'react-viewer';
import Spinner from "../../components/Spinner/Spinner";

const DetailsImmatriculation = () => {
  moment.locale('fr');
  const url = process.env.REACT_APP_URL + '/storage/';
  const { id } = useParams();
  const [immatriculationInfo, setImmatriculationInfo] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [role,setRole] = useState();
  //Image viewer
  const [isVisible, setIsVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
 
  const [vehiculeInfo, setVehiculeInfo] = useState();
  const [affectation, setAffectation] = useState("Non Affecte");
  const {genres,modeles,marques,typeVehicules,ministeres} = useContext(ComboContext);
  const {currentUserPrivilege} = useContext(UserContext);

  const [genre,setGenre] = useState();
  const [status,setStatus] = useState(false);
  const[typeVehicule,setTypeVehicule] = useState();
  const[modele,setModeles] = useState();
  const[marque,setMarque] = useState();
  const[isautreministere,setIsautreministere] = useState(false);
  const [raisonRejet,setRaisonRejet] = useState(''); 
  const [currentUser,setCurrentUser] = useState('');
  const [udpateDay,setUpdateDay] = useState('');
  const[validedUser,setValidedUser] = useState('');
  const[isvalidedPrivilege,setIsvalidedPrivilege] = useState(false);
  const [userID,setUserID] = useState('');
  const [isAgent,setisAgent] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const images = [
    {src: url + vehiculeInfo?.faceImage},
    {src: url + vehiculeInfo?.backImage},
    {src: url + vehiculeInfo?.profileImage},
    {src: url + vehiculeInfo?.lettreImage},
  ]

  const handleImageClick = (index) => {
    setImageIndex(index);
    setIsVisible(true)
  }
  const handShowRaisonRejet = ()=>{
    const raison = raisonRejet;
    Swal.fire({
      title: 'Motif de Rejet',
      text: raison,
      icon: 'info',
    })
  }
  const [pays,setPays] = useState();
  const getDetails = async (currentID) => {
    const {immatriculation,vehicule} = await getImmatriculationById(currentID)
    // console.log(data)
    setImmatriculationInfo(immatriculation) 
    setUpdateDay(moment(vehicule.updated_at).fromNow());
    setVehiculeInfo(vehicule);
   
  }
  //console.log(validedUser);
  const navigate = useNavigate();
   useMemo(() => {
    setIsLoading(true);
    if(immatriculationInfo){
      setPays(getPaysByID(vehiculeInfo?.provenance));
      if(immatriculationInfo.status === 0)
        setStatus('En attente');
      else if(immatriculationInfo.status === 1){
        setStatus('Validé');
        userByID(immatriculationInfo.valided_by).then((resp) => {
          if(resp.success){
            setValidedUser(resp.user);
          }
        });
      }
      else if(immatriculationInfo.status === 2){
        setStatus('Rejeté');
        ImmatriculationRejeter(immatriculationInfo.immatriculation_id).then((resp) => {
          if(resp?.raison != null)
             setRaisonRejet(resp.raison);
          else setRaisonRejet(resp?.autreraison);   
        });
      }
     else if(immatriculationInfo.status === 3){
      getReformeStatus(immatriculationInfo.immatriculation_id).then((resp) => {
        if(resp.success){
          if(resp.reforme.status == 0){
            setStatus("En Attente");
          }else if(resp.reforme.status == 1){
            setStatus("Valideé");
          }else if(resp.reforme.status == 2){
            setStatus("Rejetée");
          }
        }
        });
      }
     else if(immatriculationInfo.status === 4){   
        
        getMutationStatus(immatriculationInfo.immatriculation_id).then((resp) => {
          //console.log(resp)
          if(resp.success){
            if(resp.mutation.status == 0){
              setStatus("En Attente");
            }else if(resp.mutation.status == 1){
              setStatus("Validée");
            }else if(resp.mutation.status == 2){
              setStatus("Rejetée");
            }
          }
        });
      } 
     if (Array.isArray(ministeres) && ministeres.length > 0) {
       if(immatriculationInfo.minister_id != 1000000 &&  immatriculationInfo.minister_id != null ){
         const minis = ministeres.filter(m => m.ministere_id == immatriculationInfo.minister_id)[0]?.nom;
         if(minis)
            setAffectation(ministeres.filter(m => m.ministere_id == immatriculationInfo.minister_id)[0]?.nom);
         else {
          getMinistereById(immatriculationInfo.minister_id).then((resp) => {
            setAffectation(resp)
          })
         }   
       }
        else {
               setAffectation(immatriculationInfo.autreministere);  
               setIsautreministere(true);  
        }
       
      }
 
     if (Array.isArray(genres) && genres.length != 0) setGenre(genres.filter(g=>g.genre_id == vehiculeInfo.genre)[0].nom);
     if (Array.isArray(typeVehicules) && typeVehicules.length != 0) setTypeVehicule(typeVehicules.filter(g=>g.type_id == vehiculeInfo.typeVehicule)[0].nom);
     if (Array.isArray(modeles) && modeles.length != 0) setModeles(modeles.filter(m=>m.id == vehiculeInfo.model_id)[0].title);
     if (Array.isArray(marques) && marques.length != 0) setMarque(marques.filter(m=>m.id == vehiculeInfo.marque_id)[0].title);

    
      getCurrentUser().then((resp)=>{
        setUserID(resp.id); 
      });
      userByID(immatriculationInfo.created_by).then((resp) => {
        if(resp.success){
          const nomComplet = resp.user?.prenom && resp.user?.nom ? 
              resp.user?.prenom.charAt(0).toUpperCase() +resp.user?.prenom.slice(1)+" "+resp.user?.nom.charAt(0).toUpperCase() +resp.user?.nom.slice(1):'';
          setCurrentUser(nomComplet);
        }
      }); 
      if(Array.isArray(currentUserPrivilege) && currentUserPrivilege.length > 0){
        const result  = currentUserPrivilege.filter(p => p.privilege == "Nouvelle immatriculation");
        if(Array.isArray(result) && result.length > 0)
            if(immatriculationInfo.created_by == result[0].user_id)
                setisAgent(true)
        }    
    }
    setIsLoading(false);
   },[immatriculationInfo,ministeres]);
  useEffect(() => {
    setIsLoading(true);
      getDetails(id);
      getUserRole().then(resp=> setRole(resp));
      if(Array.isArray(currentUserPrivilege) && currentUserPrivilege.length > 0){
        const result  = currentUserPrivilege.filter(p => p.privilege == "Validation");
        if(Array.isArray(result) && result.length > 0)
          setIsvalidedPrivilege(true)
      }  
    setIsLoading(false); 
  }, [currentUserPrivilege]);
 
  const handValided =  (e)=>{
    e.preventDefault();
 
    Swal.fire({
      title: 'Voulez-vous valider cette immatriculation ?',
      text: "Vous ne pourrez plus revenir en arrière",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2a9d8f',
      cancelButtonColor: '#e63946',
      confirmButtonText: 'Valider',
      cancelButtonText: "Annuler"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        validedImmatriculation(id).then((resp) => { 
          if(resp.success){
            setIsLoading(false); toast.success('Immatriculation Validée avec succès.');
              navigate('/liste-immatriculation');  
          }else if(resp.success == false) 
            if(resp.message){
            setIsLoading(false);toast.error(resp.message);
          }
           else  {setIsLoading(false);toast.error("Echec de l'immatriculation.");} 
        });
      }
    })
  }

  return (
    <div className="details-immatriculation page">
      <Helmet>
        <title>Détails de l'immatriculation</title>
      </Helmet>
      <h2>Détails de l'immatriculation </h2>
      <RejectionModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} id={id} type="immatriculation"/>
        <div className="license">
          <LicensePlate numImmatriculation={immatriculationInfo?.immatriculation_number} modeImmatriculation={immatriculationInfo?.modeImmatriculation} />
        </div>
        {isLoading && <Spinner />}
        <div className="infos">
          <div className="info-group">
            <div>
              <span>Mode d'immatriculation</span>
              <strong>{immatriculationInfo?.modeImmatriculation}</strong>
            </div>
            <div>
              <span>Affectation</span>
              <strong>{affectation}</strong>
            </div>
            <div>
              <span>Numero d'immatriculation</span>
              <strong className={`numero-immatriculation ${immatriculationInfo?.modeImmatriculation === "VA" ? "green" : immatriculationInfo?.modeImmatriculation === "EP" ? "red" : ""}`}>
                {immatriculationInfo?.immatriculation_number}
              </strong>
            </div>
            <div>
              <span>Status</span>
              {raisonRejet != ''?
                  <strong style={{color:'red',cursor:'pointer',textDecoration:'underline'}} onClick={handShowRaisonRejet}> {status} </strong> 
                  :
                  <strong> {status} </strong> 
               }
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Provenance</span>
              <strong>{pays}</strong>
            </div>
            <div>
              <span>Genre de véhicule</span>
              <strong>{genre}</strong>
            </div>
            <div>
              <span>Type</span>
              <strong>{typeVehicule}</strong>
            </div>
            <div>
              <span>Marque</span>
              <strong>{marque}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Modèle</span>
              <strong>{modele}</strong>
            </div>
            <div>
              <span>Année</span>
              <strong>{vehiculeInfo?.madeYear}</strong>
            </div>
            <div>
              <span>Carosserie</span>
              <strong>{vehiculeInfo?.carosserie}</strong>
            </div>
            <div>
              <span>Energie</span>
              <strong>{vehiculeInfo?.energy}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Numero de chassis</span>
              <strong>{vehiculeInfo?.numChassie}</strong>
            </div>
            <div>
              <span>Nombre de portes</span>
              <strong>{vehiculeInfo?.nbPorte}</strong>
            </div>
            <div>
              <span>Places assises</span>
              <strong>{vehiculeInfo?.placeNumberAssis}</strong>
            </div>
            <div>
              <span>Places debout</span>
              <strong>{vehiculeInfo?.placeNumberDebout}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Premiere mise en cirulation</span>
              <strong>{moment(vehiculeInfo?.releaseYear).format("DD-MMMM-YYYY")}</strong>
            </div>
            <div>
              <span>Ancien numero d'immatriculation</span>
              <strong>{immatriculationInfo?.ancienImmatriculation}</strong>
            </div>
            <div>
              <span>Kilometrage</span>
              <strong>{vehiculeInfo?.kilometrage}</strong>
            </div>
            <div>
              <span>Cylindre</span>
              <strong>{vehiculeInfo?.cylinderNumber}</strong>
            </div>
          </div>
          <div className="info-group">
              <div>
                <span>Nombre d'essieux</span>
                <strong>{vehiculeInfo?.nbreEssuie?vehiculeInfo?.nbreEssuie:0}</strong>
             </div>
             
             <div>
                <span>Poids à vide</span>
                <strong>{vehiculeInfo?.pv?vehiculeInfo?.pv:0}</strong>
             </div>
             <div>
                <span>Charge Utile</span>
                <strong>{vehiculeInfo?.cu?vehiculeInfo?.cu:0}</strong>
             </div>
             <div>
                <span>Poids total autorisé</span>
                <strong>{parseInt(vehiculeInfo?.cu!=null?vehiculeInfo?.cu:0) + parseInt(vehiculeInfo?.pv!=null ?vehiculeInfo?.pv:0)}</strong>
             </div>
          </div>  
          <div className="info-group">
            <div>
              <span>Puissance Administrative en CV</span>
              <strong>{vehiculeInfo?.pa}</strong>
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div> 
         
              <div className="info-group">
                 <div>
                   <span>Initiateur</span>
                   <strong>{currentUser}</strong>
                 </div>
                 <div>
                    <label>
                        <span>Date</span>
                        <strong>{udpateDay}</strong>
                    </label>
                 </div>
                 {(immatriculationInfo  && validedUser) &&
                  <div>
                    <label>
                      
                      {(!isEmpty(validedUser) && validedUser)&&
                          <>
                          <span>Validé(e) par:</span>
                            <strong>
                              {validedUser?.prenom.charAt(0).toUpperCase()+validedUser?.prenom.slice(1) + " "+validedUser?.nom.charAt(0).toUpperCase()+validedUser?.nom.slice(1)}
                            </strong>
                          </>  
                          }
                    </label>
                  </div> 
                  }
                 <div></div>
              </div>  
          
          <div className="info-group">
            <div>
              <span>Photo de face</span>
              <div className="image" onClick={() => handleImageClick(0)}>
                <img src={vehiculeInfo?.faceImage !== undefined ? url + vehiculeInfo?.faceImage:''} alt="" />
              </div>
            </div>
            <div>
              <span>Photo de dos</span>
              <div className="image" onClick={() => handleImageClick(1)}>
                <img src={vehiculeInfo?.backImage !== undefined ? url + vehiculeInfo?.backImage:""} alt="" />
              </div>
            </div>
            <div>
              <span>Photo de profil</span>
              <div className="image" onClick={() => handleImageClick(2)}>
                <img src={vehiculeInfo?.profileImage !== undefined  ? url + vehiculeInfo?.profileImage:""} alt="" />
              </div>
            </div>
            <div>
              <span>Fond de Dossiers</span>
              <div className="image" onClick={() => handleImageClick(3)}>
                <img src={vehiculeInfo?.lettreImage !== undefined  ? url + vehiculeInfo?.lettreImage:""} alt="" />
              </div>
            </div>
          </div>
        </div> 
      
        <div className="image-viewer">
              <Viewer
                visible={isVisible}
                onClose={() => { setIsVisible(false); } }
                images={images}
                activeIndex={imageIndex}
              />
        </div>
        {isautreministere && immatriculationInfo?.status === 0 && isvalidedPrivilege?
          <div className="info-group" style={{paddingBottom:'12px'}}>
            <Link to={`/organisation/ministere/create/${immatriculationInfo.immatriculation_id}`}>
                <p style={{color:'#e63946',fontWeight:'bold'}}>
                  Vous ne pouvez pas valider cette immatriculation car un nouveau ministère a été ajouté par l'agent. Veuillez cliquer ici 
                  pour approuver l'ajout du ministère.
                </p>             
            </Link>    
          </div>  
        :null}
        
        {(isvalidedPrivilege && immatriculationInfo?.status === 0) ? 
          <div className="buttons">
              <button className="validate" onClick={handValided} disabled={isautreministere?true:false}>Valider</button>
              <button className="reject-btn" onClick={() => setIsModalOpen(true)} disabled={isautreministere?true:false}> Rejeter </button>
          </div>
         :null}
         {(immatriculationInfo && immatriculationInfo?.status == 2 && isAgent) &&
           <>
            <Link to='/liste-immatriculation' style={{marginRight:'5px'}}>
               <button type="button" className="secondary">Retour</button>
            </Link>
            <Link to={`/resoumission/${immatriculationInfo?.immatriculation_id}`}>
               <button className="primary">Resoumettre</button>
            </Link>
           </>
           
         } 
    </div>
  );
};

export default DetailsImmatriculation;
