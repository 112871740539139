import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { getGenreByID,getTypeByID } from "../../services/vehicule.service";
import { getMinistereById } from "../../services/organisation.service";
import { getPaysByID } from '../../utils/helper/functions';
import { nouvelleImmatriculation ,InitialiseImmatriculation, resoumission} from "../../services/immatriculation.service";
import Erreurs from "../erreurs/Erreurs";
import Viewer from 'react-viewer';
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";

const StepFive = ({
  handlePrevStep,
  immatriculation,
}) => {
  const [isGenerated, setIsGenerated] = useState(false);
  const [minister,setMinister] = useState('');
  const[genre,setGenre] = useState('');
  const[type,setType] = useState('');
  const [pays,setPays] = useState();
  const [erreurs,setErreurs] = useState([]);
  const[isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();

   //Image viewer
   const [isVisible, setIsVisible] = useState(false);
   const [imageIndex, setImageIndex] = useState(0);
   const url = process.env.REACT_APP_URL + '/storage/';
   //if (typeof myVar === 'string' || myVar instanceof String)
   // it's a string
   const images = [
    {src: typeof immatriculation.image1 === 'string' || immatriculation.image1 instanceof String ? url+immatriculation.image1:URL.createObjectURL(immatriculation.image1)},
    {src: typeof immatriculation.image2 === 'string' || immatriculation.image2 instanceof String ? url+immatriculation.image2:URL.createObjectURL(immatriculation.image2)},
    {src: typeof immatriculation.image3 === 'string' || immatriculation.image3 instanceof String ? url+immatriculation.image3:URL.createObjectURL(immatriculation.image3)},
    {src: typeof immatriculation.image4 === 'string' || immatriculation.image4 instanceof String ? url+immatriculation.image4:URL.createObjectURL(immatriculation.image4)},
  ]

  const handleImageClick = (index) => {
    setImageIndex(index)
    setIsVisible(true)
  }

  useEffect(() => {
     getMinistereById(immatriculation.ministere).then((resp) => {
       if(resp === 404 )
        setMinister(immatriculation.autreministere)
       else 
        setMinister(resp);});
     getGenreByID(immatriculation.genre).then((resp) => { setGenre(resp);});
     getTypeByID(immatriculation.type).then((resp) => { setType(resp);})
     setPays(getPaysByID(immatriculation.idpays));
  },[]);

  const submitForm = async (e) => {
    e.preventDefault();
    setErreurs([]);
    const result = (await resoumission(immatriculation));
    setIsLoading(true);
    if(result.success === false ){
      toast.error("Echec dans l'enregistrement!");
      setErreurs(result.messages);
      setIsLoading(false);
    }else{
      toast.success("Resoumission effectuée avec succès.");
      setIsLoading(false);
      navigate('/liste-immatriculation');
    }
  }

  return (
    <div className="step-five step-submit">
      {isLoading && <Spinner />}
      <div className="summary">
        <div className="info-group">
        <div>
            <span>Numero de chassis</span>
            <strong>
              {immatriculation.numChassie != ""
                ? immatriculation.numChassie
                : "Non Identifié"}
            </strong>
          </div>
          <div>
            <span>Affectation</span>
            <strong>{minister}</strong>
          </div>
          <div>
            <span>Mode d'acquisition</span>
            <strong>{immatriculation.acquisition}</strong>
          </div>
          <div>
            <span>Status</span>
            <strong>{isGenerated?"Soumise":"Non Soumise"}</strong>
          </div>
        </div>

        <div className="info-group">
        <div>
            <span>Mode d'exploitation</span>
            <strong>{immatriculation.modeImmatriculation}</strong>
          </div>
          <div>
            <span>Nombre de portes</span>
            <strong>
              {immatriculation.nbPorte != ""
                ? immatriculation.nbPorte
                : "Non Identifié"}
            </strong>
          </div>
          <div>
            <span>Places assises</span>
            <strong>
              {immatriculation.nbPlaceAssise != ""
                ? immatriculation.nbPlaceAssise
                : "Non Identifiée"}
            </strong>
          </div>
          <div>
            <span>Places debout</span>
            <strong>
              {immatriculation.nbPlaceDebout != ""
                ? immatriculation.nbPlaceDebout
                : "Non Identifiée"}
            </strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Provenance</span>
            <strong>{pays}</strong>
          </div>
          <div>
            <span>Genre de véhicule</span>
            <strong>
              { genre }
            </strong>
          </div>
          <div>
            <span>Type</span>
            <strong>
              {type}
            </strong>
          </div>
          <div>
            <span>Marque</span>
            <strong>{immatriculation.marque}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Modèle</span>
            <strong>{immatriculation.model}</strong>
          </div>
          <div>
            <span>Année</span>
            <strong>{immatriculation.annee}</strong>
          </div>
          <div>
            <span>Carosserie</span>
            <strong>
              {immatriculation.carrosserie != 0
                ? immatriculation.carrosserie
                : "Non Identifiée"}
            </strong>
          </div>
          <div>
            <span>Energie</span>
            <strong>
              {immatriculation.energie != ""
                ? immatriculation.energie
                : "Non Identifiée"}
            </strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Couleur</span>
            <strong>
              {immatriculation.couleur}
            </strong>
          </div>
          <div>
            <span>Mode de transmission</span>
            <strong>
              {immatriculation.transmission}
            </strong>
          </div>
          <div>
            <span>Kilometrage</span>
            <strong>
              {immatriculation.kilometrage != ""
                ? immatriculation.kilometrage
                : "Non Identifié"}
            </strong>
          </div>
          <div>
            <span>Cylindre</span>
            <strong>
              {immatriculation.cylindre != ""
                ? immatriculation.cylindre
                : "Non Identifié"}
            </strong>
          </div>
        </div>

        <div className="info-group">
          <div>
              <span>Nombre d'essieux</span>
              <strong>{immatriculation.nbreEssuie}</strong>
          </div>
         
          <div>
              <span>Poids à vide</span>
              <strong>{immatriculation.pv}</strong>
          </div>
          <div>
              <span>Charge Utile</span>
              <strong>{immatriculation.cu}</strong>
          </div>
          <div>
              <span>Poids total Autorisé à Charge</span>
              <strong>{parseInt(immatriculation.pv) + parseInt(immatriculation.cu)}</strong>
          </div>
        </div>
        <div className="info-group">
          <div>
            <span>Premiere mise en cirulation</span>
            <strong>
              {immatriculation.dateP != ""
                ? moment(immatriculation.dateP).format("DD/MM/YYYY")
                : "Non Identifiée"}
            </strong>
          </div>
          <div>
            <span>Ancien numero d'immatriculation</span>
            <strong>
              {immatriculation.ancienNumMat != ""
                ? immatriculation.ancienNumMat
                : "Non renseigné"}
            </strong>
          </div>
          <div>
            <span>Puissance Administrative</span>
            <strong>{immatriculation.pa + " CV"}</strong>
          </div>
          <div></div>
        </div>
        <div className="info-group">
          <div>
            <span>Vue de face</span>
            <div className="image" onClick={() => handleImageClick(0)}>
              <img
                src={typeof immatriculation.image1 === 'string' || immatriculation.image1 instanceof String ? url+immatriculation.image1:URL.createObjectURL(immatriculation.image1)}
                alt=""
                style={{ height: "100%" }}
              />
            </div>
          </div>
          <div>
            <span>Vue de dos</span>
            <div className="image" onClick={() => handleImageClick(1)}>
              <img
                src={typeof immatriculation.image2 === 'string' || immatriculation.image2 instanceof String ? url+immatriculation.image2:URL.createObjectURL(immatriculation.image2)}
                alt=""
                style={{ height: "100%" }}
              />
            </div>
          </div>
          <div>
            <span>Vue de profil</span>
            <div className="image" onClick={() => handleImageClick(2)}>
              <img
                src={typeof immatriculation.image3 === 'string' || immatriculation.image3 instanceof String ? url+immatriculation.image3:URL.createObjectURL(immatriculation.image3)}
                alt=""
                style={{ height: "100%" }}
              />
            </div>
          </div>
          <div>
            <span>Fonds de dossier</span>
            <div className="image" onClick={() => handleImageClick(3)}>
              <img
                src={typeof immatriculation.image4 === 'string' || immatriculation.image4 instanceof String ? url+immatriculation.image4:URL.createObjectURL(immatriculation.image4)}
                alt=""
                style={{ height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Erreurs validation = {erreurs} />
      <div className="image-viewer">
              <Viewer
                visible={isVisible}
                onClose={() => { setIsVisible(false); } }
                images={images}
                activeIndex={imageIndex}
              />
            </div>
      <div className="buttons">
        <button className="secondary" onClick={handlePrevStep}>
          Precedent
        </button>
        <button className="primary" onClick={submitForm}> Resoumettre </button>
      </div>
    </div>
  );
};

export default StepFive;
