import { useState,useEffect,useContext } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { ImmatriculationsData } from "../../utils/data/fakeData";
import { getAllReformes } from "../../services/immatriculation.service";
import { ComboContext } from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/fr';
import AG_GRID_TRANSLATE_FR from "../../services/utils/agGridLocalFr";
import { Helmet } from 'react-helmet-async'

const ListeReforme = () => {  
  const options = (params) => {
    const id = params.data.id;
    return (
      <div className="options">
        <Link to={`/details-reforme/${id}`}>
          <button>
            voir détails
          </button>
        </Link>
      </div>
    );
  };
  const {ministeres} = useContext(ComboContext); 
  const [immaData,setImmaData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const AG_FR = AG_GRID_TRANSLATE_FR();
  useEffect(() => {
    getReformes();
  }, [ministeres]);

  const [rowData] = useState(ImmatriculationsData);
  async function getReformes(){
    setIsLoading(true);
      setImmaData([]);
      if( Array.isArray(ministeres) && ministeres.length > 0 ){
        getAllReformes().then((resp) => {
          let ord = 0;  
          setImmaData(resp.map((data) => {
          var status = 'En attente';
          if(data.status === 1) {
            status = "Validé";
          }else if(data.status === 2){
            status = "Rejeté";
          }
          const ministere = data.ministere_id != 1000000 ? ministeres.filter((m)=>m.ministere_id === data.ministere_id)[0].nom:data.autreministere;
          //console.log(ministere)
          ord = ord + 1;
          return {
            'id': data.reforme_id,
            "Ord": ord,
            'date' : moment(data.updated_at).format('Do MMMM  YYYY'),
            'modeImmatriculation':data.modeImmatriculation, 
            'numImmatriculation':data.immatriculation_number,
            'numChassis' : data.numChassie,
            'marque': data.marque,
            'modele':data.model,
            'ministere':ministere,
            'status': status,
          }
        }));
        }) ;
      }
   setIsLoading(false);
  } 
  const [columnDefs] = useState([
    { headerName: "N°Ord.", field: "Ord", sortable: false, filter: false, width: 100 },
    { headerName: "Date", field: "date", sortable: true, filter: true, resizable: true, width: 150 },
    { headerName: "Mode Immat.", field: "modeImmatriculation", sortable: true, filter: true, resizable: true, width: 150 },
    { headerName: "Immatriculation", field: "numImmatriculation", sortable: true, filter: true, resizable: true },
    { headerName: "Numero de chassis", field: "numChassis", sortable: true, filter: true, resizable: true },
    { headerName: "Marque", field: "marque", sortable: true, filter: true },
    { headerName: "Modele", field: "modele", sortable: true, filter: true },
    { headerName: "Ministère", field: "ministere", sortable: true, filter: true, resizable: true },
    { headerName: "Status", field: "status", sortable: true, filter: true, resizable: true, width: 120 },
    { headerName: "Options", cellRenderer: options },
    { flex: 1, headerName: '' }
  ]);

  return (
    <div className="liste-reforme page">
      <Helmet>
        <title>Liste des reformes</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Liste des reformes</h2>
      <div className="ag-theme-material datagrid" style={{ width: "100%" }}>
        <AgGridReact
          rowData={immaData}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
          pagination={true}
          localeText={AG_FR}
          paginationPageSize={10}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default ListeReforme;
