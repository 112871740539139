export default function AG_GRID_TRANSLATE_FR() {
  return {
    // Set Filter
    selectAll: "(Tout sélectionner)",
    selectAllSearchResults: "( Sélectionner tout les résultats de recherche)",
    searchOoo: "Chercher...",
    blanks: "(Blancs)",
    noMatches: "Pas de correspondance",

    // Number Filter & Text Filter
    filterOoo: "Filtre...",
    equals: "Équivaut à",
    notEqual: "Inégal",
    blank: "Vide",
    notBlank: "Pas vide",
    empty: "Choisir un",

    // Number Filter
    lessThan: "Moins que",
    greaterThan: "Plus grand que",
    lessThanOrEqual: "Inférieur ou égal",
    greaterThanOrEqual: "Meilleur que ou égal",
    inRange: "dans l'interval",
    inRangeStart: "De",
    inRangeEnd: "à",

    // Text Filter
    contains: "Contenu",
    notContains: "Ne Contient Pas",
    startsWith: "Commencer Par",
    endsWith: "Terminer par",

    // Date Filter
    dateFormatOoo: "yyyy-mm-dd",

    // Filter Conditions
    andCondition: "ET",
    orCondition: "OU",

    // Filter Buttons
    applyFilter: "Appliquer",
    resetFilter: "Réinitialiser",
    clearFilter: "Dégager",
    cancelFilter: "Annuler",

    // Filter Titles
    textFilter: "Filtre de texte",
    numberFilter: "Filtre numérique",
    dateFilter: "Filtre de date",
    setFilter: "Définir le filtre",

    // Side Bar
    columns: "Colonnes",
    filters: "Filtres",

    // columns tool panel
    pivotMode: "Mode pivot",
    groups: "Groupes de lignes",
    rowGroupColumnsEmptyMessage:
      "Faites glisser ici pour définir des groupes de lignes",
    values: "Valeurs",
    valueColumnsEmptyMessage: "Faites glisser ici pour agréger",
    pivots: "Étiquettes de colonne",
    pivotColumnsEmptyMessage:
      "Faites glisser ici pour définir les étiquettes de colonne",

    // Header of the Default Group Column
    group: "Groupe",

    // Row Drag
    rowDragRows: "Lignes",

    // Other
    loadingOoo: "Chargement...",
    noRowsToShow: "Pas de Données Trouvées",
    enabled: "Activé",

    // Menu
    pinColumn: "Colonne de broches",
    pinLeft: "Broche à gauche",
    pinRight: "Broche à droite",
    noPin: "Pas de broche",
    valueAggregation: "Agrégation de valeur",
    autosizeThiscolumn: "Dimensionner automatiquement cette colonne",
    autosizeAllColumns: "Dimensionner automatiquement toutes les colonnes",
    groupBy: "Par groupe",
    ungroupBy: "Dégrouper par",
    addToValues: "Ajouter ${variable} aux valeurs",
    removeFromValues: "Supprimer ${variable} des valeurs",
    addToLabels: "Ajouter ${variable} aux libellés",
    removeFromLabels: "Supprimer ${variable} des libellés",
    resetColumns: "Réinitialiser les colonnes",
    expandAll: "Développer tout",
    collapseAll: "Ferme tout",
    copy: "Copié",
    ctrlC: "Ctrl+C",
    copyWithHeaders: "Copier avec en-têtes",
    copyWithHeaderGroups: "Copier avec les groupes d'en-tête",
    paste: "Pâte",
    ctrlV: "Ctrl+V",
    export: "Exporter",
    csvExport: "CSV Exporter",
    excelExport: "Excel Exporter",

    // Enterprise Menu Aggregation and Status Bar
    sum: "Sum",
    min: "Min",
    max: "Max",
    none: "Aucune",
    count: "Compter",
    avg: "Moyenne",
    filteredRows: "Filtrée",
    selectedRows: "Choisie",
    totalRows: "Nombre total de lignes",
    totalAndFilteredRows: "Lignes",
    more: "Suite",
    to: "à",
    of: "sur",
    page: "Page",
    nextPage: "Prochaine Page",
    lastPage: "Dernière Page",
    firstPage: "Première Page",
    previousPage: "Précédente Page",

    // Pivoting
    pivotColumnGroupTotals: "Total",

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: "Graphique croisé dynamique et mode pivot",
    pivotChart: "Tableau croisé dynamique",
    chartRange: "Plage du graphique",

    columnChart: "Column",
    groupedColumn: "Groupé",
    stackedColumn: "Empilée",
    normalizedColumn: "100% Empilée",

    barChart: "Bar",
    groupedBar: "Groupé",
    stackedBar: "Empilée",
    normalizedBar: "100% Empilée",

    pieChart: "Tarte",
    pie: "Tarte",
    doughnut: "Donut",

    line: "Line",

    xyChart: "X Y (Dispersion)",
    scatter: "Dispersion",
    bubble: "Bulle",

    areaChart: "Zone",
    area: "Zone",
    stackedArea: "Empilée",
    normalizedArea: "100% Empilée",

    histogramChart: "Histogramme",

    combinationChart: "Combinaison",
    columnLineCombo: "Colonne et Ligne",
    AreaColumnCombo: "Zone et colonne",

    // Charts
    pivotChartTitle: "Tableau croisé dynamique",
    rangeChartTitle: "Tableau de gamme",
    settings: "Réglages",
    data: "Données",
    format: "Format",
    categories: "Categories",
    defaultCategory: "(Aucun)",
    series: "Series",
    xyValues: "X Y Valeurs",
    paired: "Mode jumelé",
    axis: "Axe",
    navigator: "Navigateur",
    color: "Couleur",
    thickness: "Épaisseur",
    xType: "X Type",
    automatic: "Automatique",
    category: "Categorie",
    number: "Nombre",
    time: "Temps",
    xRotation: "X Rotation",
    yRotation: "Y Rotation",
    ticks: "Tiques",
    width: "Largeur",
    height: "Hauteur",
    length: "Longueur",
    padding: "Rembourrage",
    spacing: "Espacement",
    chart: "Graphique",
    title: "Title",
    titlePlaceholder: "Titre du graphique - double-cliquez pour modifier",
    background: "Arrière plan",
    font: "Police de caractère",
    top: "Haut",
    right: "Droite",
    bottom: "En bas",
    left: "Gauche",
    labels: "Étiquettes",
    size: "Taille",
    minSize: "Taille minimale",
    maxSize: "Taille maximale",
    legend: "Légende",
    position: "Position",
    markerSize: "Taille du marqueur",
    markerStroke: "Trait de marqueur",
    markerPadding: "Rembourrage du marqueur",
    itemSpacing: "Espacement des articles",
    itemPaddingX: "Rembourrage de l'article X",
    itemPaddingY: "Rembourrage de l'article Y",
    layoutHorizontalSpacing: "Espacement horizontal",
    layoutVerticalSpacing: "Espacement vertical",
    strokeWidth: "Largeur du trait",
    offset: "Décalage",
    offsets: "Décalages",
    tooltips: "Info-bulles",
    callout: "Faire appel à",
    markers: "Marqueurs",
    shadow: "Ombre",
    blur: "Se brouiller",
    xOffset: "X Décalage",
    yOffset: "Y Décalage",
    lineWidth: "Largeur de ligne",
    normal: "Normal",
    bold: "Audacieux",
    italic: "Italique",
    boldItalic: "Audacieux italique",
    predefined: "Prédéfini",
    fillOpacity: "Opacité de remplissage",
    strokeOpacity: "Opacité de la ligne",
    histogramBinCount: "Nombre de bacs",
    columnGroup: "Colonne",
    barGroup: "Bar",
    pieGroup: "Tarte",
    lineGroup: "Ligne",
    scatterGroup: "X Y (Dispersion)",
    areaGroup: "Zone",
    histogramGroup: "Histogramme",
    combinationGroup: "Combinaison",
    groupedColumnTooltip: "Groupé",
    stackedColumnTooltip: "Empilé",
    normalizedColumnTooltip: "100% Empilé",
    groupedBarTooltip: "Groupé",
    stackedBarTooltip: "Empilé",
    normalizedBarTooltip: "100% Empilé",
    pieTooltip: "Tarte",
    doughnutTooltip: "Donut",
    lineTooltip: "Ligne",
    groupedAreaTooltip: "Zone",
    stackedAreaTooltip: "Empilé",
    normalizedAreaTooltip: "100% Empilé",
    scatterTooltip: "Dispersion",
    bubbleTooltip: "Bulle",
    histogramTooltip: "Histogramme",
    columnLineComboTooltip: "Colonne et Ligne",
    areaColumnComboTooltip: "Zone et Colonne",
    customComboTooltip: "Combinaison personnalisée",
    noDataToChart: "Aucune donnée disponible pour être cartographiée.",
    pivotChartRequiresPivotMode:
      "Le graphique croisé dynamique nécessite l'activation du mode pivot.",
    chartSettingsToolbarTooltip: "Menu",
    chartLinkToolbarTooltip: "Lié à la grille",
    chartUnlinkToolbarTooltip: "Dissocié de la grille",
    chartDownloadToolbarTooltip: "Télécharger le graphique",
    seriesChartType: "Type de graphique de série",
    seriesType: "Type de série",
    secondaryAxis: "Axe secondaire",

    // ARIA
    ariaChecked: "vérifié",
    ariaColumn: "Colonne",
    ariaColumnGroup: "Groupe de colonnes",
    ariaColumnList: "Liste des colonnes",
    ariaColumnSelectAll: "Toggle Sélectionner toutes les colonnes",
    ariaDateFilterInput: "Entrée du filtre de date",
    ariaDefaultListName: "Liste",
    ariaFilterColumnsInput: "Filtrer l'entrée des colonnes",
    ariaFilterFromValue: "Filtrer à partir de la valeur",
    ariaFilterInput: "Filtrer l'entrée",
    ariaFilterList: "Filtrer la liste",
    ariaFilterToValue: "Filtrer par valeur",
    ariaFilterValue: "Valeur de filtre",
    ariaFilteringOperator: "Opérateur de filtrage",
    ariaHidden: "cachée",
    ariaIndeterminate: "indéterminée",
    ariaInputEditor: "Éditeur d'entrée",
    ariaMenuColumn: "Appuyez sur CTRL ENTER pour ouvrir le menu des colonnes.",
    ariaRowDeselect: "Appuyez sur ESPACE pour désélectionner cette ligne",
    ariaRowSelectAll:
      "Appuyez sur Espace pour basculer la sélection de toutes les lignes",
    ariaRowToggleSelection:
      "Appuyez sur Espace pour basculer la sélection de ligne",
    ariaRowSelect: "Appuyez sur ESPACE pour sélectionner cette ligne",
    ariaSearch: "Chercher",
    ariaSortableColumn: "Appuyez sur ENTER pour trier",
    ariaToggleVisibility: "Appuyez sur ESPACE pour basculer la visibilité",
    ariaUnchecked: "décochée",
    ariaVisible: "visible",
    ariaSearchFilterValues: "Valeurs de filtre de recherche",

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: "Groupes de lignes",
    ariaValuesDropZonePanelLabel: "Valeurs",
    ariaPivotDropZonePanelLabel: "Étiquettes de colonne",
    ariaDropZoneColumnComponentDescription:
      "Appuyez sur SUPPRIMER pour supprimer",
    ariaDropZoneColumnValueItemDescription:
      "Appuyez sur ENTRÉE pour modifier le type d'agrégation",

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: "Menu Colonne",
    ariaLabelCellEditor: "Éditeur de cellule",
    ariaLabelDialog: "Dialogue",
    ariaLabelSelectField: "Sélectionnez le champ",
    ariaLabelTooltip: "Info-bulle",
    ariaLabelContextMenu: "Menu contextuel",
    ariaLabelSubMenu: "Sous-menu",
    ariaLabelAggregationFunction: "Fonction d'agrégation",

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ",",
    decimalSeparator: ".",
  };
}
