import { useState,useEffect,useContext } from "react";
import StepFive from "../../components/Immatriculation/StepFive";
import StepFour from "../../components/Immatriculation/StepFour";
import StepOne from "../../components/Immatriculation/StepOne";
import StepThree from "../../components/Immatriculation/StepThree";
import StepTwo from "../../components/Immatriculation/StepTwo";
import {getAllGenre, getAlltype,getAllMarques,getAllmodel} from "../../utils/vehicule.util";
import {getministeres,getAllDirections} from '../../services/organisation.service';
import { ComboContext } from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import { Helmet } from 'react-helmet-async';
const Immatriculation = () => {
  const [step, setStep] = useState(1);
  const [stepChk, setStepChk] = useState({step1:false,step2:false,step3:false,step4:false});
  const { genres, modeles, marques, typeVehicules, ministeres,directions } = useContext(ComboContext);
  //const [models,setModels] = useState();
  const [items,setItems] = useState({});
  const [isLoading,setIsLoading] = useState(false);
  const [immatriculation,setImmatriculation] = useState(
    {modeImmatriculation:'',
    idpays: 'DE',
    marque: 0,
    model: '0',
    carrosserie:'Berline',
    genre: '0',
    type: '0',
    annee:'2010',
    energie:'Essence',
    numChassie:'JN1FCAN15U0004510',
    nbPorte:5,
    proprietaire: 0,
    acquisition:'achat',
    transmission:'Manuelle',
    nbPlaceAssise:4,
    nbPlaceDebout:4,
    dateP:'2010-04-07',
    carosserie:'',
    ancienNumMat:'RC-8938-BA',
    cylindre:4,
    kilometrage:'1000',
    couleur:'Rouge',
    dateImmat:'',
    ministere: 0,
    direction:'',
    autreministere:'',
    nbreEssuie:2,
    autredirection:'',
    categories:{
     AllGenres:[],
     AllTypes:[],
    },
    brands:{
      allMarques:[],
      allModeles:[]
    },
    organisations:{
     allMinisteres:[],
     allDirections:[],
    },
    ptc:24,
    pv:10,
    cu:15,
    pa:'0',
    image1:'',
    image2:'',
    image3:'',
    image4:'',
  }); 

   function ComboElements(){
    setIsLoading(true);
    setImmatriculation({...immatriculation,brands:{
      allMarques:marques,
      allModeles:modeles,
    },categories:{
      AllGenres:genres,
      AllTypes:typeVehicules
    },organisations:{
      allMinisteres:ministeres,
      allDirections:directions
    }});
    setIsLoading(false);
   }

  useEffect(() => {
    ComboElements();
  },[genres]);

  const handleNextStep = () => {
    setStep(step + 1);
  }

  const handlePrevStep = () => {
    setStep(step - 1);
  }
  return (
    <div className="immatriculation-page page">
      <Helmet>
        <title>Nouvelle immatriculation</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Nouvelle immatriculation</h2>
      <div className="stepper">
        <div className={`step ${step === 1 && "step-active"}`} onClick={ () => setStep(1) }>
          <div className="step-number">
            <span>1</span>
          </div>
          <div className="step-label">
            <p>Mode d'immatriculation</p>
          </div>
        </div>
        <div className={`step ${step === 2 && "step-active"}`} onClick={ () => immatriculation.modeImmatriculation.length > 0 && setStep(2) }>
          <div className="step-number">
            <span>2</span>
          </div>
          <div className="step-label">
            <p>Infos du vehicule</p>
          </div>
        </div>
        <div className={`step ${step === 3 && "step-active"}`} onClick={ () => stepChk.step3 && setStep(3) }>
          <div className="step-number">
            <span>3</span>
          </div>
          <div className="step-label">
            <p>Infos du proprietaire</p>
          </div>
        </div>
        <div className={`step ${step === 4 && "step-active"}`} onClick={ () => stepChk.step4 && setStep(4) }>
          <div className="step-number">
            <span>4</span>
          </div>
          <div className="step-label">
            <p>Joindre des fichiers</p>
          </div>
        </div>
        <div className={`step ${step === 5 && "step-active"}`} onClick={ () => stepChk.step5 &&setStep(5) }>
          <div className="step-number">
            <span>5</span>
          </div>
          <div className="step-label">
            <p>Soumission</p>
          </div>
        </div>
      </div>
      <div className="step-components">
            {step === 1 && <StepOne handleNextStep={handleNextStep} immatriculation={immatriculation} setImmatriculation = {setImmatriculation} />}
            {step === 2 && <StepTwo handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} immatriculation={immatriculation}  setImmatriculation = {setImmatriculation} items={items} stepChk={stepChk} setStepChk={setStepChk} />}
            {step === 3 && <StepThree handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} immatriculation={immatriculation} setImmatriculation = {setImmatriculation} stepChk={stepChk} setStepChk={setStepChk} />}
            {step === 4 && <StepFour handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} immatriculation={immatriculation} setImmatriculation = {setImmatriculation} stepChk={stepChk} setStepChk={setStepChk} />}
            {step === 5 && <StepFive handlePrevStep={handlePrevStep} setImmatriculation = {setImmatriculation} immatriculation={immatriculation} stepChk={stepChk} setStepChk={setStepChk} setStep ={setStep} />}
       
      </div>
    </div>
  );
};

export default Immatriculation;
